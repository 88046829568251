<template>
	<Teleport to="#modalScreen">
		<div class="fixed top-0 left-0 w-screen h-screen z-80">
			<!-- Black Background -->
			<div
				name="Blackground"
				class="bg-black opacity-75 w-screen h-screen absolute top-0 left-0"
				@click="() => close()"
			/>

			<!-- The Pop Up -->
			<div
				class="bg-white lg:w-1/2 w-3/4 z-90 mx-auto lg:top-20 top-2 relative rounded shadow-lg  lg:py-5 lg:px-5 py-2 px-2"
				style="overflow: scroll; word-wrap: break-word;"
			>
				<h1 class="text-center">Secret Code Required</h1>
				<h3
					v-if="type === undefined || type === 'quest'"
					class="text-center"
				>
					This Quest has been reserved by the group
					<span class="underline">{{ guildName }}</span
					>, which means it's only available to members of that
					volunteer Guild on Charity Quest.
				</h3>
				<p class="text-center mx-10">
					If your Guild Leader gave you a secret four character
					passcode, please enter it below. You'll be added to the
					Guild, and able to sign up for any Quest they have reserved.
				</p>

				<!-- Input Box -->
				<!-- don't allow them to press button unless we have 4 chars. limited to 4 alphanumeric chars -->
				<div class="flex items-center justify-center h-2/3">
					<input
						v-model="textarino"
						placeholder="????"
						class="text-7xl text-center w-2/3 py-1"
						type="text"
						maxlength="4"
						size="4"
						@input="validateCodeInput"
					/>
					<br />
					<br />
				</div>
				<br />

				<!-- The buttons -->
				<div class="mx-auto grid grid-cols-2 gap-4">
					<button
						class="border-none bg-red-600 text-white rounded-lg py-3 px-5 hover:bg-red-700 hover:cursor-pointer ease-in-out transition-all duration-100 drop-shadow-md hover:scale-105 hover:drop-shadow-lg"
						@click="() => close()"
					>
						Oh, this isn't my Guild...
					</button>
					<button
						class="border-none bg-green-600 text-white rounded-lg py-3 px-5 hover:bg-green-700 hover:cursor-pointer ease-in-out transition-all duration-100 drop-shadow-md hover:scale-105 hover:drop-shadow-lg whitespace-normal"
						@click="() => handleTryCode(textarino)"
						style="padding: 10px 15px; font-size: 1rem; min-width: fit-content; max-width:100%;"
					>
						Ready, let me join!
					</button>
				</div>
			</div>
		</div>
	</Teleport>
</template>

<script>
import { Teleport } from "vue";

export default {
	props: ["groupData", "handleTryCode", "close", "type"],
	components: {
		Teleport,
	},
	data() {
		return {
			textarino: "",
		};
	},
	setup(props) {
		console.log("Showing Secret Guild Code panel...");
		console.log(props);
		// console.log(props.close);

		return {
			guildName: props.groupData,
		};
	},
	methods: {
		validateCodeInput() {
			// Ensure that everything is capitalized
			this.textarino = this.textarino.toUpperCase();
		},
	},
	computed: {
		foobar() {
			console.log("FOOBAH! " + guildName);
			return true;
		},
	},
};
</script>

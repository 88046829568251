<template>
  <div class="passwordResetBox cq-orange">
    <!--<img class="cq-logo" alt="Charity Quest logo" src="../assets/CQ-Logo.png" />-->

    <h1 class="text-center">Reset Your Password</h1>
    <h4>In order to reset your password, you can request a reset email from the PlayFab service that will be sent to the email address you enter below.<br><br>The email will contain a link that allows you to reset your password. Once that's done, return to the previous screen and log in with your new password.</h4>
    
    <form class="passwordResetForm" @submit.prevent="onSubmit">

      <div class="labelInputRow">
        <label for="passwordResetEmail">Email:</label>
        <input 
          id="passwordResetEmail"
          v-model="passwordResetEmail"
          type="email"
          placeholder="Email"
        >
      </div>

      

      <input class="passwordResetButton" type="submit" value="REQUEST RESET   ►">

      <!-- Appeal for autologin goes here, togglebox -->
    </form>

    <br>
    <div class="my-8"></div>
  </div>
</template>

<script>
export default {
  name: "LoginForm",
  data() {
    return {
      passwordResetEmail: ''
    }
  },
  methods: {
    onSubmit() {
      window.PlayFab.settings.titleId = "FEAAC"
      
      var sendAccountRecoveryEmailRequest = {
        Email: this.passwordResetEmail,
        TitleId: "FEAAC"
      }

      window.PlayFabClientSDK.SendAccountRecoveryEmail(sendAccountRecoveryEmailRequest, this.SendAccountRecoveryEmailCallback)
    },
    SendAccountRecoveryEmailCallback(result, error) {
      // console.log("Callback received, sarge!")
      
      if ( result != null )
      {
        // console.log("Sending account email succeeded!")
        // console.log(result)
        alert("An email has been sent to this address. Check your email and follow those instructions to reset your password. Thanks!")
      }
      else if ( error != null )
      {
        // console.log("Sending account email failed...")
        
        if ( error.error == "InvalidEmailAddress" )
        {
          alert("There is no Hoboken Charity Quest account that uses this email address. Please confirm your spelling and try again.\n\nAre you 100% certain that this is the email you used to create your account?")
        }
        else if ( error.error == "AccountNotFound" )
        {
          alert("There's no account that uses this Login Email. Please check that you've spelled it correctly, and that you're using the email that you created the account with.\n\nIf this is definitely your email address, please email Frank for help at contact@GameRevenant.com")
        }
        else if ( error.error == "InvalidParams" )
        {
          alert("The email you entered could not be recognized as an email, probably because of a typo. It should look like \"email@provider.com\", for example.\n\nPlease check your spelling and try again.")
        }
        else if ( error.error == "ServiceUnavailable" )
        {
          alert("Service is unavailable.\n\nCheck your Internet connection and try again.")
        }
        else
        {
          window.PlayFab.GenerateErrorReport(error)
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.passwordResetBox {
  background-color: #FF000000;
  width: 650px;
  padding-top: 50px;
  margin: 0 auto;         /* Set the app to be centered */
}

h1 {
  color: white;
}

h4 {
  color: #FFFFFF;
  font-weight: 400;
  text-align: left;
  margin-top: 20px;
  margin-right: 100px;
  margin-left: 100px;
  margin-bottom: 20px;
}

img {
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: cover;
  background: #ff000000;
}

.passwordResetForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  text-align: left;
  width: 300px;
  border-radius: 4px;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
  margin: 0 auto;         /* Set the app to be centered */
}

label {
  float: left;
  text-align: right;
  margin-right: 10px;
  padding-bottom: 10px;
  outline-color: #FFA94E;
  width: 100px;
}

.passwordResetButton {
  background-color: #FFA94E;
  border: none;
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px 50px;
  outline: none;

  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
  /* box-shadow: 0 1px 1px 1px rgba(0,0,0,0.1); */
}

.passwordResetButton:hover {
  background-color: #fdbd78;
}

.passwordResetButton:active {
  background-color: #ffa13c;
  transform: translateY(1px);
}
</style>

<template>
  <div id="page-container">
    <TopNav v-show="true"/>
    <div id="content-wrap">
      <div class="questDetails">
        <h1>{{ $store.state.currentlyViewedQuest.eventTitle }}</h1>
        
        <!-- Here, I would really like to mention the name of the charity! -->
        <h2>{{ $store.state.currentlyViewedQuest.providerName }}</h2>

        <p>{{ new Date($store.state.currentlyViewedQuest.date).toDateString() + " from " + $store.state.currentlyViewedQuest.startTime + " to " + $store.state.currentlyViewedQuest.endTime }}</p>
        <br>

        <!--
        <h4>Mission:</h4>
        <div class="special-class" markdown="1">
          {{ $store.state.currentlyViewedQuest.description1 + " " + $store.state.currentlyViewedQuest.description2 + " " + $store.state.currentlyViewedQuest.description3 }}
        </div>
        <br>
        -->

        <!-- *NEW* Quest Description that allows for Markdown -->
        <h4>Description:</h4>
        <div v-html="questDescription1" class="questDescription"></div>
        <div v-html="questDescription2" class="questDescription"></div>
        <div v-html="questDescription3" class="questDescription"></div>
        <br>

        <h4>Location:</h4>
        <p>{{ $store.state.currentlyViewedQuest.locationName }}</p>
        <p>{{ $store.state.currentlyViewedQuest.locationAddress }}</p>
        <p>{{ $store.state.currentlyViewedQuest.locationDetail }}</p>
        <br>

        <div class="questDetailsFlexbox">

          <!-- GROUP, IF ONE HAS CLAIMED THIS -->
          <!-- = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = -->
          <div class="questDetailsGroupBox" v-if="isRaided == true">
            <p class="questDetailsGroupTitle">GROUP</p>
            <p class="questDetailsGroupName">{{ $store.state.currentlyViewedQuest.raidInfo.raidingGroupName }}</p>
          </div>

          <!-- VOLUNTEERS -->
          <!-- = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = -->
          <ul class="questDetailsVolunteerList">
            <h3>Volunteers {{ "( " + $store.state.currentlyViewedQuest.volunteerInfo.length + " of " + $store.state.currentlyViewedQuest.volunteerMax + " )" }}</h3>
          
            <!-- WORK HERE -->
            <li class="questDetailsVolunteerListOther" v-for="item in $store.state.currentlyViewedQuest.volunteerInfo" :key=item.userID>
              <div class="attendance-leftSide">
                {{ item.firstName + " " + item.lastName + " (" + item.age + ")" }}
                <br>
                {{ item.emailAddress }}
                <br>
                <!-- Taking it on faith that these are 10 digit numbers here -->
                <template v-if="item.phoneNumber != null">
                  {{ "(" + item.phoneNumber.substring(0, 3) + ") " + item.phoneNumber.substring(3, 6) + "-" + item.phoneNumber.substring(6, 10) }}
                </template>
                <br>
                <template v-if="checkAttendance(item, 'Present')">
                  Marked Present ({{ $store.state.currentlyViewedQuest.timeCredit / 60 }}) hours
                </template>
                <template v-else-if="checkAttendance(item, 'Absent')">
                  Marked Absent ( 0.0 hours )
                </template>
              </div>

              <div class="attendance-rightSide">
                <!-- Checkmark for PRESENT -->
                <button class="attendanceButton">
                  <img class="attendanceButtonImage" v-if="ready == true && checkAttendance(item, 'Present')" alt="A checkmark to show this volunteer was marked present." src="../assets/CQ-Butticon_Present.png"/>
                </button>

                <!-- X for ABSENT -->
                <button class="attendanceButton">
                  <img class="attendanceButtonImage" v-if="checkAttendance(item, 'Absent')" alt="An X to show this volunteer was marked absent." src="../assets/CQ-Butticon_Absent.png"/>
                </button>

                <!-- ? for UNMARKED -->
                <!-- Removed because it was confusing I think
                <button class="attendanceButton">
                  <img class="attendanceButtonImage" v-if="checkAttendance(item, 'Upcoming')" alt="A question mark to show that the charity hasn't marked this yet." src="../assets/CQ-Butticon_Unknown.png"/>
                  <img class="attendanceButtonImage" v-else alt="A question mark to show that the charity has marked this volunteer as present or absent." src="../assets/CQ-Butticon_Unknown_Grey.png"/>
                </button>
                -->
              </div>
            </li>
            <!-- WORK HERE-->













            <li class="questDetailsVolunteerListEmpty" v-for="n in slotsRemaining" v-bind:key=n>
              Open Slot
            </li>
          </ul>
        </div>

      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// QuestDetails shows you everything about the Quest.
// Intended to be accessed via QuestList OR your ActiveQuests.
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "QuestDetails",
  components: {
    TopNav,
    Footer
  },
  props: ['id'],
  data() {
    return {
      chariteerAttendanceArray: [],
      ready: false
    }
  },
  created() {
    console.log("Fetching a Past Event for " + this.id)
    this.$store.dispatch('fetchPastEvent', this.id)

    // this.printPlayerInventory()

    // This may be a good time to take the data from the Internet about the Actual Attendance of our Volunteers and store it in Data for use later
    // Later happens pretty soon because the part where it shows volunteers needs to be preset with this stuff
    // so data {} needs some kind of array of key value pairs like { id: BFFID123BBQ711 actualAttendance: Absent } and that determines how the buttons look when you first pull this up.
    // you can obviously edit that client side, which is then saved in data ("don't hit the back button!") and can be actually saved if you hit the Save button.
  
    var i = 0
    var newCopyOfActualAttendance

    for ( i; i < this.$store.state.currentlyViewedQuest.volunteerInfo.length; i++ )
    {
      newCopyOfActualAttendance = {}

      newCopyOfActualAttendance.userID = this.$store.state.currentlyViewedQuest.volunteerInfo[i].userID
      newCopyOfActualAttendance.markedAttendance = this.$store.state.currentlyViewedQuest.volunteerInfo[i].actualAttendance

      this.chariteerAttendanceArray.push(newCopyOfActualAttendance)
    }

    console.log(this.chariteerAttendanceArray)
    this.ready = true
  },
  methods: {
    checkAttendance(item, checkString)
    {
      // console.log("The length of the array is " + this.chariteerAttendanceArray.length + ". Assessing if " + item.userID + " is " + checkString + "...")

      var i = 0
      for ( i; i < this.chariteerAttendanceArray.length; i++ )
      {
        // console.log("Loop " + i)
        if ( this.chariteerAttendanceArray[i].userID == item.userID )
        {
          // console.log(this.chariteerAttendanceArray[i].userID + " is " + item.userID + "!")
          
          // Found it. Now to check the string.
          if ( this.chariteerAttendanceArray[i].markedAttendance == checkString )
          {
            // console.log(this.chariteerAttendanceArray[i].markedAttendance + " is " + checkString)
            return true
          }
          else
          {
            // console.log(this.chariteerAttendanceArray[i].markedAttendance + " is NOT " + checkString)
            return false
          }
        }
      }

      // If we can't find the ID, which would be... odd... return false?
      return false
    },
    markAttendance(item, newMarkedAttendance)
    {
      console.log("Let's mark " + item.firstName + " " + item.lastName + " " + newMarkedAttendance + ".")

      var i = 0
      for ( i; i < this.chariteerAttendanceArray.length; i++ )
      {
        // console.log("Loop " + i)
        if ( this.chariteerAttendanceArray[i].userID == item.userID )
        {
          // console.log(this.chariteerAttendanceArray[i].userID + " is " + item.userID + "!")
          
          // Found it. Now to change the value
          this.chariteerAttendanceArray[i].markedAttendance = newMarkedAttendance
          
          return
        }
      }
      
      return
    },
    signUpToVolunteer() {
      console.log("Chariteer clicked the Sign Up button!")

      // Tracking
      var tagsObj = this.$store.state.currentlyViewedQuest
      tagsObj.messageType = "Chariteer Clicks Volunteer Button"
      this.$store.dispatch('clickTrackLogForAmazon', tagsObj)

      // Execution
      this.$store.dispatch('signUpToVolunteerForQuest')
    },
    bailOutOfQuest() {
      console.log("Chariteer clicked the Bail Out button!")
      
      // Tracking
      var tagsObj = this.$store.state.currentlyViewedQuest
      tagsObj.messageType = "Chariteer Clicks Bail Button"
      this.$store.dispatch('clickTrackLogForAmazon', tagsObj)

      // Execution
      this.$store.dispatch('bailOutOfQuest')
    },
    returnToQuestList() {
      this.$router.push('/questList')
    },
    printPlayerInventory() {
      console.log("THIS IS OUR CURRENT INVENTORY:")

      for ( var i = 0; i < this.$store.state.inventoryContracts.length; i++ )
      {
        console.log("CONTRACT #" + i + " is a " + this.$store.state.inventoryContracts[i].DisplayName + " for Quest " + this.$store.state.inventoryContracts[i].CustomData.questID + " of Charity " + this.$store.state.inventoryContracts[i].CustomData.charityID + ". ItemInstanceId: " + this.$store.state.inventoryContracts[i].ItemInstanceId )
      }
    }
  },
  computed: {
    myComputedThing() {
      return this.$store.state.currentlyViewedQuest.volunteerMax
    },
    chariteerIsOnThisQuest() {
      for ( var i = 0; i < this.$store.state.currentlyViewedQuest.volunteerInfo.length; i++ )
      {
        if ( this.$store.state.playerProfile.masterPlayerID == this.$store.state.currentlyViewedQuest.volunteerInfo[i].userID )
        {
          // console.log("We got a match, we're on this Quest!")
          return true
        }
        // console.log( this.$store.state.currentlyViewedQuest.volunteerInfo[i].userID )
      }
      return false
    },
    questIsFull() {
      if ( this.$store.state.currentlyViewedQuest.volunteerInfo.length >= this.$store.state.currentlyViewedQuest.volunteerMax )
      {
        // console.log("This quest is full.")
        return true
      }
      else
      {
        // console.log("This quest has at least 1 open spot.")
        return false
      }
    },
    slotsRemaining() {

      return (this.$store.state.currentlyViewedQuest.volunteerMax - this.$store.state.currentlyViewedQuest.volunteerInfo.length)
    },
    providerHasSaveChanges() {
      // This is where I need to compare my local data with the data from the internet
      // if it's the same, it's the same and you return FALSE because there are NO CHANGES
      // otherwise it's true. So I think false is the default. If you're looping and you hit something thatd oesn't match you return immediately as TRUE

      var i = 0
      for ( i; i < this.chariteerAttendanceArray.length; i++ )
      {
        //console.log("Loop " + i)
        if ( this.chariteerAttendanceArray[i].markedAttendance != this.$store.state.currentlyViewedQuest.volunteerInfo[i].actualAttendance )
        {
          console.log(this.chariteerAttendanceArray[i].markedAttendance + " is NOT " + this.$store.state.currentlyViewedQuest.volunteerInfo[i].actualAttendance + "!")
          
          // I've seen enough! We have something different than the last thing we got from the server.

          return true
        }
      }

      return false
    },
    isRaided () {
      if ( this.$store.state.currentlyViewedQuest.raidInfo != null && this.$store.state.currentlyViewedQuest.raidInfo.raidingGroupID != "" )
      {
        return true
      }
      else
      {
        return false
      }
    },
    questDescription1() {
      return this.$store.state.currentlyViewedQuest.description1
    },
    questDescription2() {
      return this.$store.state.currentlyViewedQuest.description2
    },
    questDescription3() {
      return this.$store.state.currentlyViewedQuest.description3
    }
  }
};
</script>

<style scoped>

#page-container {
  background-color: #FFA94E;
  position: relative;
  min-height: 100vh;
}

h1, h2, h4, p {
  margin-left: 20px;
  margin-right: 20px;
}

.special-class {
  margin-left: 20px;
  margin-right: 20px;
}

.questDescription {
  margin-left: 20px;
  margin-right: 20px;
}

.questDetails {
  background-color: white;
  border-radius: 5px;
  text-align: left;
  width: 500px;
  padding-top: 10px;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 50px;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.25);
}

.questDetailsFlexbox {
  background-color: #00000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: 0px auto;
}

.questDetailsGroupBox {
  box-shadow: 0 1px 5px 3px rgba(0,0,0,0.25);
  text-align: center;
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
  padding-top: 1vh;
  padding-right: 2.5vw;
  padding-left: 2.5vw;
  padding-bottom: 1vh;
}

.questDetailsGroupTitle {
  font-size: 125%;
  font-weight: 900;
  margin: 0px;
}

.questDetailsGroupName {
  font-size: 110%;
  font-weight: 500;
  margin: 0px;
}

.questDetailsVolunteerList {
  background-color: rgba(0, 0, 0, 0.0);
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 20px;
  box-shadow: 0 1px 5px 3px rgba(0,0,0,0.25);
}

.questDetailsVolunteerList h3 {
  text-align: center;
}

/* Volunteer Flex Box */
.questDetailsVolunteerListOther {
  display: flex;
  align-items: center;
  color: #444444;
  background-color: rgb(240, 240, 240);
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 0px;
  padding: 10px;
}

.attendance-leftSide {
  flex: 3;
}

.questDetailsVolunteerListPlayer {
  background-color: #FFA94E;
}

.questDetailsVolunteerListEmpty {
  color: white;
  background-color: #bbbbbb9d;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 0px;
  padding: 10px;
}

.questDetailsFlexbox p {
  background-color: #00000000;
}









.questDetails-signUpButton {
  background-color: #22c913;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 20px 50px;
  outline: none;

  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
}

.questDetails-signUpButton:hover {
  background-color: #23da13;
}

.questDetails-signUpButton:active {
  background-color: #20a513;
  transform: scale(0.95);
}

.questDetails-bailOutButton {
  background-color: #e44444;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 20px 50px;
  outline: none;

  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
}

.questDetails-bailOutButton:hover {
  background-color: #f55353;
}

.questDetails-bailOutButton:active {
  background-color: #b82323;
  transform: scale(0.95);
}













/* Attendance Buttons */
.attendanceButton {
  background-color: transparent;
  padding-top: 0px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 0px;
  margin: 0px;
  height: 32px;
  border: none;
}

.attendanceButtonImage {
  background-color: transparent;
  padding: 0px;
}



















#content-wrap {
  padding-top: 50px;
  padding-bottom: 100px;    /* Footer height */
}

@media screen and (max-width: 800px) {
  #page-container {
    max-width: 100%;
  }
  #content-wrap {
    background-color: rgba(255, 47, 227, 0.5);
    max-width: 100%;
  }
  .questDetails {
    max-width: 100%;
  }
}

</style>

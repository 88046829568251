<template>
	<div>
		<div
		    class="inline-flex items-center justify-center overflow-hidden rounded-full"
		  >
		  	<div class="relative">
		  		<span 
		  			:style="{ fontSize: '16px', color: textColor }"
		  			class="z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold whitespace-nowrap">{{ text }}</span>
		  			<img src="placeholder" class="w-20 h-20 invisible" />
		  	</div>
		    <svg :height="radius * 2" :width="radius * 2" class="-rotate-90 absolute">
			      <circle
			        className="text-gray-300"
			        :stroke-width="stroke"
			        stroke="transparent"
			        :fill="fillColor"
			        :r="normalizedRadius - 3"
			        :cx="radius"
			        :cy="radius"
			      />
		    	
				  <!-- Interior Grey Donut -->
				  <circle
			        :class="strokeColor"
			        :stroke-width="stroke"
			        :stroke-dasharray="circumference"
			        :stroke-dashoffset="0"
			        stroke-linecap="cap"
			        stroke="#eeeeee"
			        fill="transparent"
			        :r="normalizedRadius"
			        :cx="radius"
			        :cy="radius"
			      />

			      <circle v-if="percent > 0"
			        :class="strokeColor"
			        :stroke-width="stroke"
			        :stroke-dasharray="circumference"
			        :stroke-dashoffset="offset"
			        stroke-linecap="cap"
			        :stroke="strokeColor"
			        fill="transparent"
			        :r="normalizedRadius"
			        :cx="radius"
			        :cy="radius"
			      />

				  

		    </svg>
		  </div>
	</div>
</template>

<script>
	export default {
		props: {
			Radius: Number,
			Stroke: Number,
			Percent: Number,
			Text: String,
			FillColor: String,
			StrokeColor: String
		},
		setup(props) {
			const radius = props.Radius || 70; // Default circle size is 70
			const stroke = 9; // Size of stroke
			const percent = props.Percent || 0; // Default is 0
			const text = props.Text || "99 / 100";
			const textColor = 'black';
			const fillColor = props.FillColor || 'transparent';
			const strokeColor = props.StrokeColor || '#FFA94E';
			const size = 'w-14 h-14'; 

			const normalizedRadius = radius - (stroke * 2)
			const circumference = normalizedRadius * 2 * Math.PI;
			const offset = circumference - percent / 100 * circumference;
	
			return {
				radius,
				stroke,
				percent,
				text,
				textColor,
				fillColor,
				strokeColor,
				size,
				normalizedRadius,
				circumference,
				offset
			}
		}
	}
</script>
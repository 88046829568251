<template>
  <!-- The entire thing, including placards beneath the button -->
  <div v-if="shouldWeEvenShowThisTypeOfQuest && dumbFilter">
    <button
      :style="{ backgroundColor: backgroundColorStatus }"
      class="hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 w-full border border-gray-400 rounded shadow"
      v-on:click="onClick"
    >
      <!-- v-if="quest.providerId == $store.state.currentlyViewedProvider.charityID" -->
      <div class="flex flex-row relative p-4">
        <!-- This Div is the Floating Medallion on the Left -->
        <div class="absolute -left-12">
          <!-- Is this Quest locked for some reason? -->
          <template
            v-if="!chariteerIsManager && !chariteerIsOnThisQuest && questIsFull"
          >
            <IconPicker name="locked quest" size="tiny" />
          </template>
          <template v-else-if="chariteerIsManager && questIsOver && quest?.eventType != 'HYBRID-ROLE'">
            <IconPicker name="warning triangle" size="tiny" />
          </template>
          <template
            v-else-if="
              quest?.serviceTags != undefined && quest?.serviceTags.length > 0
            "
          >
            <IconPicker :name="quest?.serviceTags[0]" size="tiny" />
          </template>
          <template v-else>
            <IconPicker name="generic medallion" size="tiny" />
          </template>
          <!-- TO DO: Yellow Triangle for "this Quest is in the past and you need to take attendance!"-->
          <!-- TO DO: Lock for full Quests, checkmark for full quests (managers)"-->
        </div>

        <!-- This Div is the Left Side -->
        <div class="flex-initial flex-1 w-64 pl-3">
          <h2 class="text-slate-700 text-left">{{ quest?.eventTitle }}</h2>
          <h3 class="text-slate-700 text-left">
            {{ "Location: " + quest?.location?.name }}
          </h3>
          <div v-if="quest?.startedAt != null">
            <p class="text-slate-600 text-left">
              {{
                questDayName +
                  ", " +
                  questMonthName +
                  " " +
                  questDayNumberProper
              }}
            </p>
          </div>
          <div v-else-if="quest?.eventType == 'HYBRID-ROLE'">
            <br />
            <!-- <p class="text-slate-600 text-left">{{ "Flexible Hybrid Role" }} </p> -->
          </div>
          <!-- <p>{{ "Currently Viewed Provider: " + $store.state.currentlyViewedProvider.charityID }}</p> -->
          <!-- <p>{{ "Provider Id: " + quest.providerId }}</p> -->

          <!-- Is this a Flex Quest? -->
          <p
            v-if="
              quest?.flexibleMinimumTime == undefined ||
                quest?.flexibleMinimumTime <= 0
            "
            class="text-slate-600 text-left"
          >
            {{ questComboStartEndTimeDisplay }}
          </p>
          <p v-else class="text-slate-600 text-left">
            {{ "Flexible from " + questStartTime + " to " + questEndTime }}
          </p>
        </div>

        <!-- This Div is the Right Side -->
        <!-- TO DO, make this "right side" a Vue Component -->
        <!-- Are we in the Management Group? -->
        <template v-if="chariteerIsManager && quest?.eventType != 'HYBRID-ROLE'">
          <div>
            <!--<p class="text-center">{{ "Pledged Contracts: " + quest.pledgedContracts.length }}</p>-->
            <!-- <ProgressCircle :Radius="60" :Percent="resourceFillPercentage" :Text="resourceCircleText" StrokeColor="#FF0000"/> THIS IS HOW YOU INSIST ON A COLOR -->
            <ProgressCircle
              :Radius="60"
              :Percent="resourceFillPercentage"
              :Text="resourceCircleText"
              :StrokeColor="crisisColor"
            />
            <p>{{ resourceFlavorText }}</p>
          </div>
        </template>

        <!-- Maybe this is a Hybrid Role? -->
        <template v-else-if="quest?.eventType == 'HYBRID-ROLE'">
          <div>
            <img
              class="questCard-guildFlagImage"
              alt="Quill and parchment"
              src="../assets/CQ-Navicon_Apply.png"
            />
            <p>{{ "Apply for this" }}</p>
          </div>
        </template>

        <!-- If we're not in the Management Group, we're just normal men... -->
        <template v-else>
          <div>
            <!--<p class="text-center">{{ "Pledged Contracts: " + quest.pledgedContracts.length }}</p>-->
            <!-- <ProgressCircle :Radius="60" :Percent="resourceFillPercentage" :Text="resourceCircleText" StrokeColor="#FF0000"/> THIS IS HOW YOU INSIST ON A COLOR -->
            <ProgressCircle
              :Radius="60"
              :Percent="resourceFillPercentage"
              :Text="resourceCircleText"
              :StrokeColor="selfishVolunteerColor"
            />
            <p>{{ resourceFlavorText }}</p>
          </div>
          <!-- The old way was showing a flag instead of the Volunteer Circle
          <div v-else-if="isRaidedByRivals == true">
            <img class="questCard-guildFlagImage"  alt="Quest is claimed by another Guild." src="../assets/quest-guild-flag.png" />
            <p>{{ "Taken by Group" }}</p>
          </div>
          -->
        </template>
      </div>
    </button>

    <!-- Under the button -->
    <!-- Small centered titlecard that indicates a Guild has claimed this Quest Card -->
    <div
      class="text-white bg-gray-500 text-center my-0"
      v-if="isRaidedByRivals == true"
    >
      Claimed by Guild: {{ raidingGuildName }}
    </div>
    <div
      class="text-white bg-gray-500 text-center my-0"
      v-else-if="isRaidedByYourGroup == true"
    >
      Claimed by your Guild: {{ raidingGuildName }}
    </div>
  </div>
</template>

<script>
import IconPicker from "@/components/IconPicker.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import {
  siftVolunteerContracts,
  siftGuildContracts,
  isChariteerManager
} from "@/helpers/index.js";

export default {
  props: {
    quest: Object
  },
  components: {
    IconPicker,
    ProgressCircle
  },
  methods: {
    onClick() {
      console.log("Clicking on Quest ID " + this.quest.id + "...");

      // YOU WOULD... Branch for different Database Quests
      // if ( this.quest.eventType == "OSV" )
      // {
      //   console.log("Load up OSVQuestDetails")
      //   this.$router.push({ name: 'OSVQuestDetails', params: {id: this.quest.eventID, providerID: this.quest.providerId}})
      // }
      // else
      // {
      //   console.log("Unrecognized Database Quest Type")
      // }

      // Lazy
      console.log(
        "Changing route to Quest " +
          this.quest.id +
          " from Provider " +
          this.quest.providerId
      );
      this.$router.push({
        name: "OSVQuestDetails",
        params: { id: this.quest.id, providerID: this.quest.providerId }
      });
      console.log("I'm assuming this code never runs because of the error...");
    }
  },
  computed: {
    questDayName() {
      var dayIndex = new Date(this.quest.startedAt).getDay();

      switch (dayIndex) {
        case 0:
          return "Sunday";
        case 1:
          return "Monday";
        case 2:
          return "Tuesday";
        case 3:
          return "Wednesday";
        case 4:
          return "Thursday";
        case 5:
          return "Friday";
        case 6:
          return "Saturday";
      }

      return "Day";
    },
    questMonthNumber() {
      return new Date(this.quest.startedAt).getMonth() + 1;
    },
    questMonthName() {
      var monthIndex = new Date(this.quest.startedAt).getMonth() + 1;

      switch (monthIndex) {
        case 0:
          return "January";
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
      }

      return "Day";
    },
    questDayNumber() {
      return new Date(this.quest.startedAt).getDate();
    },
    questDayNumberProper() {
      var dayIndex = new Date(this.quest.startedAt).getDate();
      var dayEnder = "th";

      // 1st, 21st, 31st
      if (dayIndex == 1 || dayIndex == 21 || dayIndex == 31) dayEnder = "st";
      // 2nd, 22nd
      else if (dayIndex == 2 || dayIndex == 22) dayEnder = "nd";
      // 3rd, 23rd
      else if (dayIndex == 3 || dayIndex == 23) dayEnder = "rd";

      return dayIndex + dayEnder;
    },
    questComboStartEndTimeDisplay() {
      if (
        this.questEndTime == null &&
        this.quest.eventType == "SUPPLY-REQUEST"
      ) {
        return "Deliver at " + this.questStartTime;
      } else if (
        this.questEndTime == null &&
        this.quest.eventType == "HYBRID-ROLE"
      ) {
        return "Flexible Hybrid Role";
      } else if (this.questEndTime == null) {
        return this.questStartTime;
      } else {
        return this.questStartTime + " to " + this.questEndTime;
      }
    },
    questStartTime() {
      var time = new Date(this.quest.startedAt);
      // TO DO make the am / pm lowercase
      return time.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    questEndTime() {
      if (this.quest.endedAt == null) {
        return null;
      } else {
        var time = new Date(this.quest.endedAt);
        return time.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true
        });
      }
    },
    questIsOver() {
      const now = new Date();
      const questTime = new Date(this.quest.startedAt);

      if (now.getTime() - questTime.getTime() > 0) {
        // console.log(this.quest.eventTitle + " is so over!");
        return true;
      }
      {
        // console.log(this.quest.eventTitle + " is so back!");
        return false;
      }
    },
    resourceCircleText() {
      var firstNumber = this.theSiftedContractArray.length;

      // console.log("Sifted Array for Resource Circle Text");
      // console.log(siftedArray);
      // console.log(this.quest.pledgedContracts);

      return firstNumber + " / " + this.quest.resourceMaxNumber;
    },
    resourceFillPercentage() {
      // console.log("Active Contracts of " + this.quest.eventTitle + " is " + this.theSiftedContractArray.length);
      var spotsFilled = this.theSiftedContractArray.length;
      var returnVal = 0;

      if (spotsFilled >= this.quest.resourceMaxNumber) {
        returnVal = 100;
      } else {
        returnVal = Math.floor(
          (spotsFilled / this.quest.resourceMaxNumber) * 100
        );
        // console.log(returnVal);
      }

      return returnVal;
    },
    resourceFlavorText() {
      var volunteersNeeded = 0;
      volunteersNeeded =
        this.quest.resourceMaxNumber - this.theSiftedContractArray.length;

      // Don't allow this text to say "Needs -1 more"
      // "Quest is overloaded" didn't fit and messed up the formatting

      if (this.chariteerIsOnThisQuest) {
        return "Signed up!";
      } else if (volunteersNeeded <= 0 && this.chariteerIsManager == false)
        return "Quest is full.";
      else if (
        volunteersNeeded == 0 &&
        this.chariteerIsManager == true &&
        this.quest.eventType == "SUPPLY-REQUEST"
      )
        return "Meal donated!";
      else if (volunteersNeeded == 0 && this.chariteerIsManager == true)
        return "Quest is full!";
      else if (volunteersNeeded < 0 && this.chariteerIsManager == true)
        return "Quest is full!";
      else return "Needs " + volunteersNeeded + " more";
    },
    theSiftedContractArray() {
      return siftVolunteerContracts(this.quest.pledgedContracts);
    },
    theSiftedGuildContractArray() {
      return siftGuildContracts(this.quest.pledgedContracts);
    },
    chariteerIsManager() {
      return isChariteerManager(
        this.$store.state.managedProviderGroupMemberships,
        this.quest.providerId
      );
    },
    chariteerIsOnThisQuest() {
      var i = 0;

      if (this.theSiftedContractArray != undefined) {
        for (i = 0; i < this.theSiftedContractArray.length; i++) {
          if (
            this.$store.state.playerProfile.masterPlayerID ==
            this.theSiftedContractArray[i].chariteerId
          ) {
            // console.log("We got a match, we're on this Quest!");

            return true;
          }
        }
      }

      // console.log("If we got here, our ID doesn't match any of the contracts.");

      return false;
    },
    raidingGuildName() {
      var siftedArray = siftGuildContracts(this.quest.pledgedContracts);
      // console.log("What's the raiding Guild's name, if we have one?");
      // console.log(siftedArray);
      if (siftedArray.length == 1) {
        // console.log("We have one");
        return siftedArray[0].guild.name;
      } else {
        // console.log("No Raiding Guild");
        return "Unknown Guild";
      }
    },
    isRaidedByRivals() {
      // Does this Quest have any active Guild Contracts?
      var ongoingRaids = 0;
      var siftedArray = siftGuildContracts(this.quest.pledgedContracts);
      ongoingRaids = siftedArray.length;

      if (ongoingRaids > 0) {
        // Let's examine this raid... are we in this raiding group?
        // console.log("We have at least 1 Ongoing Raid!");
        var i = 0;

        for (i = 0; i < this.$store.state.groupMemberships.length; i++) {
          // If we find a match, return false because this is not a Rival Group!
          if (
            this.$store.state.groupMemberships[i].Group.Id ==
            siftedArray[0].guildId
          ) {
            return false;
          }
        }

        // No match found. This is a Rival Guild!
        return true;
      } else {
        // Impossible, there are no Ongoing Raids!
        return false;
      }
    },
    isRaidedByYourGroup() {
      // Does this Quest have any active Guild Contracts?
      var ongoingRaids = 0;
      var siftedArray = siftGuildContracts(this.quest.pledgedContracts);
      ongoingRaids = siftedArray.length;

      if (ongoingRaids > 0) {
        // Let's examine this raid... are we in this raiding group?
        console.log("We have at least 1 Ongoing Raid!");
        var i = 0;

        for (i = 0; i < this.$store.state.groupMemberships.length; i++) {
          // If we find a match, return true because this is one of ours!
          if (
            this.$store.state.groupMemberships[i].Group.Id ==
            siftedArray[0].guildId
          ) {
            return true;
          }
        }

        // No match found. This is a Rival Guild!
        return false;
      } else {
        return false;
      }
    },
    gradientStatus() {
      return true;
    },
    backgroundColorStatus() {
      if (this.chariteerIsOnThisQuest == true) {
        // Green
        return "#A7FFA0";
      } else if (this.chariteerIsManager == false) {
        if (
          this.theSiftedContractArray.length >= this.quest.resourceMaxNumber
        ) {
          // Grey
          return "#c9c9c9";
        }
        // don't forget. you also want to grey out a Quest if it's been raided by rivals.
        // use a computed for that!
      }

      return "#FFFFFF";
    },
    questIsFull() {
      if (this.theSiftedContractArray.length >= this.quest.resourceMaxNumber) {
        return true;
      }
      return false;
    },
    selfishVolunteerColor() {
      if (this.chariteerIsOnThisQuest) {
        return "#4DCB1D";
      } else if (
        this.theSiftedContractArray.length >= this.quest.resourceMaxNumber
      ) {
        // Unfortunately for us, the Quest is full. We can't be of service. And that's terrible.
        return "#818181";
      }

      // We're in luck, there's room for at least one more.
      return "#FFA94E";
    },
    crisisColor() {
      if (this.theSiftedContractArray.length >= this.quest.resourceMaxNumber) {
        // We have all the volunteers we could ever hope for!
        return "#4DCB1D";
      } else if (
        this.theSiftedContractArray.length >= this.quest.resourceDesiredNumber
      ) {
        // We have more than the desired number
        return "#A6CB1D";
      } else if (
        this.theSiftedContractArray.length >= this.quest.resourceMinNumber
      ) {
        // We have more than the bare minimum
        return "#CB7C1D";
      }

      // We're in a heap of trouble
      return "#CB321D";
    },
    shouldWeEvenShowThisTypeOfQuest() {
      // if ( this.quest.eventType == "SUPPLY-REQUEST" && this.chariteerIsManager == false )
      // {
      //   // Supply Requests are not ready for the public yet, but the shelter staff needs them!
      //   return false;
      // }

      return true;
    },
    dumbFilter() {
      // For Xavier High School

      // if ( this.theSiftedGuildContractArray.length == 0 )
      // {
      //   return false;
      // }

      return true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  margin: 0px;
}

.questCard-guildFlagImage {
  max-width: 100px;
  max-height: 100px;
  opacity: 0.5;
}
</style>

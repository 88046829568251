<template>
  <div class="groupApplicationsBox">

    <div class="groupApplications">

      <!-- What if the data hasn't been downloaded yet, or there's 0 Applicants? -->
      <template v-if="$store.state.currentlyViewedGroupApplications.length == 0">
        <p>There are no pending applications to review.</p>
      </template>


      <!-- 1 or more Applicants -->
      <template v-else-if="$store.state.currentlyViewedGroupApplications.length > 0">

        <p v-if="$store.state.currentlyViewedGroupApplications.length == 1">{{ "There is 1 Application to review."}}</p>
        <p v-else>{{ "There are " + $store.state.currentlyViewedGroupApplications.length + " Applications to review."}}</p>

        <ul>
          <li v-for="application in $store.state.currentlyViewedGroupApplications" :key="application.Entity.Lineage.master_player_account.Id" class="groupApplicant" v-bind:class="{ acceptedApplication: application.isPending == false }">
            <template v-if="application.isPending">
              <p>{{ application.firstName + " " + application.lastName + " would like to join " + $store.state.currentlyViewedGroup.GroupName + "." }}</p>
              <button v-on:click="acceptToGroup(application.Entity.Key, application.Entity.Lineage.master_player_account.Id)">Accept to Group</button>
            </template>
            <template v-else>
              <p>{{ application.firstName + " " + application.lastName + " has been accepted into " + $store.state.currentlyViewedGroup.GroupName + "!" }}</p>
            </template>
          </li>
        </ul>
      </template>
      
    </div>
    
  </div>
</template>

<script>
export default {
  name: "GroupApplications",
  data() {
    return {
      somekindafilterinfo: ''
    }
  },
  methods: {
    acceptToGroup ( playerEntityKey, masterPlayerID )
    {
      console.log("Time to accept the Entity that's " + playerEntityKey.Id + " and " + playerEntityKey.Type)

      playerEntityKey.playFabID = masterPlayerID

      // This is done with the currentlyViewedGroup in mind
      this.$store.dispatch('acceptApplicationToGroup', playerEntityKey)
    },
  },
  created() {
    console.log("Group Applications active.")

    // This is only set up for 1 group right now because I would need to do more work if someone belonged to
    // multiple groups and they wanted to see all the info for one of those specific groups. So if you're in
    // more than one we're just interacting with whatever is in the [0] slot.
    this.$store.dispatch('downloadGroupApplications', this.$store.state.groupMemberships[0].Group)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.groupApplicationsBox {
  background-color: #00000000;
  width: 800px;
  padding: 0px;
  margin: 0px auto;         /* Set the box to be centered */
}

p {
  text-align: justify;
  margin: 0px;
}

ul {
  margin: 0px;
  padding: 0px;
}

.groupApplications {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  background-color: #ffffff;
  text-align: left;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.25);
}

.groupApplicant {
  display: flex;
  justify-content: space-between;

  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 15px;
  padding-right: 20px;
  padding-left: 15px;
  padding-bottom: 15px;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);
}

.acceptedApplication {
  color: white;
  background-color:rgba(63, 219, 58, 0.836);

  font-weight: 600;
}



@media only screen and (max-width: 600px) {
  .groupApplicationsBox {
    width: 100%;
  }
}
</style>

<template>
    <h1>{{ $store.state.currentlyViewedProvider.charityName }} Past Quests</h1>
    <div class="questListFlexbox">
      <div class="questList">
        <ProviderPastQuestCard v-for="quest in $store.state.providerCompletedQuests.specialEvents" v-bind:key="quest.eventID" v-bind:quest="quest"/>

        <p v-if="$store.state.providerCompletedQuests.specialEvents.length == 0">You do not currently have any Past Quests.<br>Quests appear here after they have ended.</p>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import ProviderPastQuestCard from "@/components/ProviderPastQuestCard.vue";

export default {
  name: "ProviderSchedule",
  data() {
    return {
      daysArray: [],
      pastQuests: this.$store.state.providerCompletedQuests
    }
  },
  components: {
    ProviderPastQuestCard
  },
  props: ['providerID'],
  created() {
    console.log("Pasht Questh")
    console.log(this.$store.state.providerCompletedQuests)
  },
  beforeMount() {
    console.log("Before Mount in ProviderPastQuests")
  }
};
</script>

<style scoped>
h1 {
  color: white;
  margin-top: 100px;
}

p {
  color: white;
  margin: 0px;
}

.questList {
  padding-top: 0px;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

.questListFlexbox {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0px auto;         /* Set the app to be centered */
  padding-top: 30px;
  padding-bottom: 110px;    /* This is for the footer */
}

@media screen and (max-width: 800px) {
  .questListFlexbox {
    max-width: 100%;;
  }
}
</style>
<template>
    <h1>{{ $store.state.currentlyViewedProvider.charityName }} Schedule</h1>
    <div class="questListFlexbox">
      <div class="questList">
        <ProviderQuestCard v-for="quest in $store.state.events" v-bind:key="quest.eventID" v-bind:quest="quest"/>

        <p v-if="$store.state.events.length == 0">You do not currently have any Quests scheduled.<br>Go to the Calendar or the Quests menu to add one.</p>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import ProviderQuestCard from "@/components/ProviderQuestCard.vue";

export default {
  name: "ProviderSchedule",
  data() {
    return {
      daysArray: [],
      events: this.$store.state.events
    }
  },
  components: {
    ProviderQuestCard
  },
  props: ['providerID'],
  created() {
    // console.log("About to fetch events!")
    // console.log("Specifically, for " + this.providerID)
    this.$store.dispatch('fetchEvents', this.providerID)
  },
  beforeMount() {
    console.log("Before Mount in ProviderSchedule:")
    console.log(this.events)
  }
};
</script>

<style scoped>
h1 {
  color: white;
  margin-top: 100px;
}

p {
  color: white;
  margin: 0px;
}

.questList {
  padding-top: 0px;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

.questListFlexbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0px auto;         /* Set the app to be centered */
  padding-top: 30px;
  padding-bottom: 110px;    /* This is for the footer */
}

@media screen and (max-width: 800px) {
  .questListFlexbox {
    max-width: 100%;
  }
  .questList {
    width: 90vw;
  }
}
</style>
<template>
  <!-- Take a look at a single Chariteer before going to their full profile. -->
  <div>
    <!-- <p>It's a me, Mario. {{ this.databaseChariteer.chariteer }}</p>-->
  </div>
  <Teleport to="#modalScreen">
    <div class="fixed top-0 left-0 w-screen h-screen z-80">
        <!-- Black Background -->
        <div name="Blackground" class="bg-black opacity-75 w-screen h-screen absolute top-0 left-0" @click="() => close()" />
        
        <!-- The Pop Up -->
        <div class="bg-white w-2/3 h-2/3 z-90 mx-auto top-20 relative py-5 px-5">
          <div class="flex flex-row-reverse">
            <button class="bg-gray-200 hover:bg-gray-300 rounded-full p-2 px-3 border-none" @click="() => close()">✕ Close</button>
          </div>
          <h1 class="text-center">{{  this.databaseChariteer?.chariteer.nameFirst + " " + this.databaseChariteer?.chariteer.nameLast }}</h1>         
          <!-- Essential Data -->
          <div>
            <p class="m-0"><b>Phone Number:</b> {{ formattedPhoneNumber }}</p>
            <p class="m-0"><b>Email Address:</b> {{ this.databaseChariteer?.chariteer.emailAddress }}</p>
            <p class="m-0"><b>Current Age:</b> {{ calculatedAge }}</p>
            <br>
          </div>
          <!-- What Quests is this user planning to attend? -->
          <div>
            <p class="m-0">{{this.databaseChariteer?.chariteer.nameFirst}} has had {{ siftVolunteerContracts.length }} interactions with your charity:</p>
            
            <div class="bg-gray-100 h-[280px] overflow-y-auto">
              <ul class="m-0 p-0">
                <li v-for="questContract in siftVolunteerContracts" :key="questContract.id" class="list-none m-0">
                  <!-- Left Edge: The Emblem 
                  <div>
                    <img class="quest-category-icon" alt="Quest category icon" src="../assets/CQ-Medallion.png" />
                  </div>-->

                  <!-- Left Side: Text Details -->
                  <!-- TO DO: what about the hours earned by the volunteer? -->
                  <!-- Desktop Version -->
                  <div class="hidden md:grid grid-cols-6 bg-gray-200 justify-between m-0 my-5">
                    <p :class="statusStyle">{{ questContract.status }}</p>
                    <p class="m-0">{{ questContract.quest.eventTitle }}</p>
                    <!--<p class="m-0">0 h 0 m</p> -->
                    <p class="m-0 col-span-2">on {{ questDayDisplay(questContract.quest.startedAt) }}</p>
                    <p class="m-0 text-right">by {{ questContract.quest.giverName }}</p>
                  </div>
                  <!-- Mobile Version -->
                  <div class="grid md:hidden grid-cols-2 bg-gray-200 justify-between gap-2 m-0 my-5">
                    <p :class="statusStyle">{{ questContract.status }}</p>
                    <p class="m-0">{{ questContract.quest.eventTitle }}</p>
                    <!-- <h4>{{ completedQuest.providerName }}</h4> -->
                    <p class="m-0">on {{ questDayDisplay(questContract.quest.startedAt) }}</p>
                    <p class="m-0">by {{ questContract.quest.giverName }}</p>
                  </div>
                </li>
              </ul>
            </div>






          </div>
        </div>
        
          
    </div>

    </Teleport>
</template>

<script>

// We need to pull ALL the Guilds from the database so Hamis / Alyson / Rev can choose one
// from the list and reserve a Quest for that Guild. This can be more robust in the future,
// with filters for different kinds of Guilds as well as location restrictions (which makes the
// query more managable) but the naive implementation here is just "let's get every single Guild"
import { useQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';
import { Teleport } from 'vue'
import { ref } from 'vue';
import gql from 'graphql-tag'
import { siftVolunteerContractsSingleProvider } from '@/helpers/index.js';

export default {
  name: "ChariteerPreview",
  components: {
    Teleport
  },
  created() {
    console.log("Chariteer Preview created.");
  },
  props: ['chariteerData', 'close'],
  setup(props) {
    console.log("Volunteer id is " + props.chariteerData.id);
    const route = useRoute();      
    const { result, loading, error, refetch, onResult } = useQuery(gql`
            query GetChariteerById {
              chariteer (id: "${props.chariteerData.id}" ) {
              id
              username
              nameFirst
              nameMiddle
              nameLast
              playerAccountType
              contracts {
                id
                quest {
                  eventTitle
                  providerId
                  giverName
                  startedAt
                }
                abandonedAt
                expectedArrivalAt
                expectedDepartureAt
                actualArrivalAt
                actualDepartureAt
                status
                guildId
              }
              emailAddress
              phoneNumber
              dateOfBirth
            }
        }
       `,
       )
      
      console.log("Refetching Chariteer!");
      console.log(result);
      console.log("Can we see the Chariteer we refetched?");
      console.log(result.chariteer);
      console.log(error);
      if (props.chariteerData == null )
      {
         console.log("Chariteer Preview has no chariteer data to work with here...");
      }
      else
        console.log(props.chariteerData);
       
    return {
      databaseChariteer: result || null,
      loading,
      error,
      refetch,
      onResult
    }
  },
  methods: {
    questDayDisplay( inputDate ) {
      var date = new Date(inputDate);

      return date.toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    }
  },
  computed: {
    formattedPhoneNumber () {
      // Turn it nice and pretty
      let formattedNumber = "(" + this.databaseChariteer?.chariteer.phoneNumber.substring(0, 3) + ") " + this.databaseChariteer?.chariteer.phoneNumber.substring(3, 6) + "-" + this.databaseChariteer?.chariteer.phoneNumber.substring(6, 10);
      
      return formattedNumber;
    },
    calculatedAge() {
      var age = -1;
      var today = new Date();
      var birthDate = new Date(this.databaseChariteer?.chariteer.dateOfBirth);
      age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }

      return age;
    },
    statusStyle ()
    {
      return "text-black font-bold m-0";
    },
    siftVolunteerContracts() {
      
      return siftVolunteerContractsSingleProvider(this.databaseChariteer?.chariteer.contracts);
    },
  }
}
</script>

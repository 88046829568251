<template>
    <div class="rounded-lg shadow-sm p-4 border bg-gray-100 m-0 mb-5">
        <h3
            class="m-0 cursor-pointer"
            v-on:click="handleOnClick(chariteerData)"
        >
        {{ chariteerData.nameFirst }} {{ chariteerData.nameLast }}
        </h3>
        
        <div v-if="filterPreset == 'Leaderboard'" class="grid grid-cols-2 justify-between m-0">
            <p class="m-0">{{ chariteerData.username }}</p>
            <p class="m-0 text-xl text-right">{{ minutesVolunteeredTextDisplay(minutesVolunteered) }}</p>
        </div>
        <div v-else-if="filterPreset == 'Contact Information'" class="grid grid-cols-2 justify-between m-0">
            <p class="m-0">{{ chariteerData.emailAddress }}</p>
            <p class="m-0">{{ chariteerData.phoneNumber }}</p>
        </div>
    </div>

</template>

<script>
export default {
    props: ['chariteerData', 'minutesVolunteered', 'filterPreset', 'handleOnClick'],
    methods: {
        minutesVolunteeredTextDisplay ( mins )
        {
            let hoursText = mins / 60;
            hoursText = Math.floor(hoursText);
            if ( hoursText == 1 )
                hoursText = "1 hour";
            else
                hoursText = hoursText + " hours";

            let minutesText = mins % 60;
            if (minutesText == 0)
                minutesText = "";
            else
                minutesText = ", " + minutesText + " minutes";
            
            return hoursText + minutesText;
        }
    }
}
</script>
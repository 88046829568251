<template>
  <div class="bg-[#ffa94e] min-h-screen mt-0 p-8">
    <h2 class="text-center text-white mb-2">
      {{ district.name }}
    </h2>
    <p class="text-white text-sm mb-4 text-center">
      {{ district.declaredChariteers.length }} volunteers
    </p>
    <div class="flex flex-wrap justify-center gap-2">
      <router-link
        v-for="user in district.declaredChariteers"
        :key="user.id"
        :to="`/chariteers/${user.id}`"
      >
        <button
          class="bg-white text-sm py-2 px-4 rounded-lg cursor-pointer hover:bg-gray-200 transition-colors duration-200 stroke-[#ffa94e] "
        >
          {{ user.username }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "district",
  props: {
    district: {
      type: Object,
      default: () => ({
        id: 0,
        name: "",
        acronym: "",
        county: "",
        state: "",
        noun: "",
        centerLatitude: 0,
        centerLongitude: 0,
        zoomLevel: 0,
        declaredChariteers: []
      }) // Provide a default empty object to avoid undefined errors
    }
  }
};
</script>

<template>
  <div class="noob">
    
    <HelloNoob/>

    <!--
    <p>There's {{ $store.getters.serviceTypesLength }} categories of charity on this app!</p>
    -->

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloNoob from "@/components/HelloNoob.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Noob",
  components: {
    HelloNoob,
    Footer
  }
};
</script>

<style scoped>
.noob {
  background-color: rgba(0, 128, 0, 0.0);
  height: 100%;
  margin: 0px;
}

p {
  margin: 0px;
}

</style>

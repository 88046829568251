<template>
  <TopNav/>
  <div class="bg-cq-orange">
    <ClashOfClubs/>
  </div>
  <Footer/>
</template>

<script>
import TopNav from '../components/TopNav.vue'
import Footer from '../components/Footer.vue'
import ClashOfClubs from "../components/ClashOfClubs.vue";

export default {
  name: "Contest",
  components: {
    TopNav,
    ClashOfClubs,
    Footer
}
};
</script>

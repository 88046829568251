<template>
  <div class="home">
    <TopNav />
    <div class="flex flex-row bg-cq-orange-light">
      <!-- Stamps needs to know which Provider we're looking at currently -->
      <Stamps
        v-model:selectedManagedProviderId="
          this.$store.state.currentlySelectedManagedProviderGroup
            .ManagedProviderId
        "
        v-if="true"
      />
      <div id="Calendar and Controls Together" class="grow">
        <FDCalendar
          v-if="this.$store.state.currentlySelectedManagedProviderGroup
              .ManagedProviderId"
          v-model:selectedManagedProviderId="
            this.$store.state.currentlySelectedManagedProviderGroup
              .ManagedProviderId
          "
          v-model:managedProviderMemberships="this.managedProviderMemberships"
          ref="child"
          class="mt-5"
        />
      </div>
    </div>
    <Footer v-show="false" />
  </div>
</template>

<script>
import TopNav from "@/components/TopNav.vue";
import Stamps from "@/components/calendar/Stamps.vue";
import FDCalendar from "@/components/calendar/FDCalendar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    TopNav,
    Stamps,
    FDCalendar,
    Footer
  },
  data() {
    return {
      selectedManagedProviderId:
        this.$store.state.managedProviderGroupMemberships?.find(item => true)
          ?.ManagedProviderId || "",
      managedProviderMemberships: this.$store.state
        .managedProviderGroupMemberships
    };
  },
  methods: {
    onChange(e) {
      // const entireObject = USE ARRAY.FIND TO GET THE GROUPINFO OBJECT BASED ON THE PROVIDER ID
      this.selectedManagedProviderId = e.target.value;
      console.log("Do we have access to the right child?");
      console.log(this.$refs.child);
      console.log(this.$refs.child.refetch);
      console.log("We're refetching with dates, too:");
      console.log(this.$refs.child.calendarDisplayStartDateTime);

      // Reload the Calendar with the new Provider ID
      this.$refs.child.refetch({
        GQI: {
          specificProvider: e.target.value,
          dateRangeBeginning: this.$refs.child.calendarDisplayStartDateTime,
          dateRangeEnding: this.$refs.child.calendarDisplayEndDateTime
          // isUpcoming?
          // isPublished?
        }
      });
    }
  },
  computed: {
    dropdownHasMultipleChoices() {
      if (this.managedProviderMemberships.length > 1) return true;
      else return false;
    }
  }
};
</script>

<template>
  <div class="helloNoob">
    <div class="content-wrapper">
      <div class="left-space"></div>

      <!-- Choose to log in or make an account. Big Logo. -->
      <div class="decisionBox">
        <div class="decisionBox-verticalCenterer">
          <!-- Beeg Logo -->
          <img class="hcq-logo" alt="Hoboken Charity Quest logo" src="../assets/HCQ-Logo.png" />

          <!-- Your choices -->
          <div class="mainButtonsFlexbox">
            <!-- Button to Create Account -->
            <button class="button-create-chariteer-account" v-on:click="navigateDirectlyToCreateChariteerAccount">
                Create an account
            </button>

            <!-- Button to Log In -->
            <button class="button-create-chariteer-account" v-on:click="navigateToLogIn">
                Log in
            </button>
          </div>
        </div>
      </div>

      <div class="middle-space"></div>

      <!-- A reminder of the mobile app, with links to download it. -->
      <div class="mobileAppLinkBox">
        <div class="mobileAppLinkBox-verticalCenterer">
          <!-- Call to action text -->
          <h2>Hoboken Charity Quest is also available on the go. Download the mobile app for free!</h2>

          <!-- Call to action buttons -->
          <div class="mobileBadgesFlexBox">
            <a class="mobileBadge" href="https://apps.apple.com/us/app/hoboken-charity-quest/id1486635465" target="_blank">
              <img alt="The App Store logo" src="../assets/Badge-AppStore.png" />
            </a>
            <a class="mobileBadge" href="https://play.google.com/store/apps/details?id=com.GameRevenant.CharityQuest" target="_blank">
              <img alt="Google Play logo" src="../assets/Badge-GooglePlay.png" />
            </a>
          </div>
        </div>
      </div>

      <div class="right-space"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloNoob",
  methods: {
    navigateDirectlyToCreateChariteerAccount() {
      this.$router.push('/createChariteerAccount')
    },
    navigateToLogIn() {
      this.$router.push('/login')
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.helloNoob {
  background-color: rgba(0, 0, 255, 0.0);
  width: 100%;
  height: 90%;
  margin: 0px auto;
  /* padding-top: 50px; */
}

.hcq-logo {
  width: 100%;
  max-width: 720px;
  height: auto;
  margin-bottom: 30px;
  object-fit: cover;
  background: #ff000000;
}

h2 { 
  color: white;
  margin: 0 auto;
  width: 400px;
}

/* Isn't helloNoob the content wrapper? */
.content-wrapper {
  background-color: rgb(0, 0, 0, 0);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}

.left-space {
  width: 250px;
  order: 1;
}

.middle-space {
  background-color: rgba(255, 255, 255, 0);
  width: 25px;
  order: 3;
}

.right-space {
  width: 150px;
  order: 5;
}

/* Should be on the left in wide screen mode, on top for mobile.*/
.decisionBox {
  background-color: rgba(144, 238, 144, 0.0);
  display: flex;
  order: 2;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

/* Should be on the right in wide screen mode, on bottom for mobile.*/
.mobileAppLinkBox {
  background-color: rgba(255, 99, 71, 0.0);
  display: flex;
  order: 4;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

/* This guides the display of the clickable mobile badges */
.mobileBadgesFlexBox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
  width: 50%;
  height: 75px;
  background-color: rgba(128, 128, 128, 0);
}

.mobileBadge {
  padding: 15px;
}

.mobileBadge img {
  flex-grow: 1;
  width: 100%;
  min-height: 0px;
  max-height: 75px;
  margin-right: 10px;
  margin-left: 10px;
}

.mobileBadgesFlexBox img:hover {
  transform: scale(1.01);
}

.mainButtonsFlexbox {
  background-color: #00000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 60%;
}

.mainButtonsFlexbox button {
  background-color: rgb(245, 245, 245);
  border: none;
  border-radius: 4px;
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 15px 25px;
  outline: none;
  width: 100%;

  color: #444444;
  font-size: 125%;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.25);
}

.mainButtonsFlexbox button:hover {
  background-color: white;
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.50);
}

.mainButtonsFlexbox button:active {
  background-color: white;
  transform: scale(0.95);
}



/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {
  .content-wrapper {
    background-color: rgba(251, 255, 10, 0);
    flex-wrap: wrap;
  }
}
</style>

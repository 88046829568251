<template>
  <div class="charityList">
    <TopNav />
    <FDFullWidthBanner />

    <div v-if="favDistricts">
      <h1 class="text-center">
        View your favorite districts
      </h1>
      <div v-for="district in favoriteDistrictFull" v-bind:key="district.id">
        <div v-if="districtHasCharities(district.id)">
          <div class="flex flex-col justify-center items-center mb-0">
            <div class="flex flex-row justify-center items-end gap-x-2">
              <div v-if="favDistricts">
                <LikeButton
                  :isLiked="districtIsFavorite(district.id)"
                  @click="() => handleLikeClick(district.id)"
                />
              </div>
              <h2 class="text-center mr-4 mb-0">
                The
                {{
                  district.noun.charAt(0).toUpperCase() + district.noun.slice(1)
                }}
                of {{ district.name }},
                {{ district.state }}
              </h2>
            </div>
            <p class="text-white text-s hover:cursor-pointer underline mt-0">
              <router-link
                active-class="active"
                :to="`/district/${district.id}`"
                class="text-white text-s hover:cursor-pointer hover:text-gray-200 underline"
              >
                {{ district.declaredChariteers.length }} volunteers
              </router-link>
            </p>
          </div>
          <div class="charityListFlexbox">
            <CharityCard
              v-for="charity in providersInSpecificDistrict(district.id)"
              v-bind:key="charity.id"
              v-bind:charity="charity"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="districtLoading" class="flex flex-col items-center min-h-[80vh]">
      <div class="flex flex-col w-full justify-center items-center">
        <h1 class="text-center">
          Select a charity to view their upcoming Quests
        </h1>
        <div class="w-full flex flex-row justify-center items-end">
          <Skeleton width="10%" height="3rem" borderRadius="5px" />
          <Skeleton width="5%" height="1rem" borderRadius="5px" />
        </div>
      </div>
      <div class="charityListFlexbox w-full mt-20">
        <Skeleton
          width="250px"
          height="5rem"
          borderRadius="15px"
          class="mx-5"
        />
        <Skeleton
          width="250px"
          height="5rem"
          borderRadius="15px"
          class="mx-5"
        />
        <Skeleton
          width="250px"
          height="5rem"
          borderRadius="15px"
          class="mx-5"
        />
      </div>
    </div>
    <div v-else-if="error">An error occurred</div>
    <div v-else>
      <div v-if="districtList">
        <h1 class="text-center">
          Select a charity to view their upcoming Quests
        </h1>
        <h2>
        </h2>
        <div
          v-for="district in districtList.districts"
          v-bind:key="district.id"
        >
          <div v-if="districtHasCharities(district.id)">
            <div class="flex flex-col justify-center items-center mb-0">
              <div class="flex flex-row justify-center items-end gap-x-2">
                <div v-if="favDistricts">
                  <LikeButton
                    :isLiked="districtIsFavorite(district.id)"
                    @click="() => handleLikeClick(district.id)"
                  />
                </div>
                <h2 class="text-center mr-4 mb-0">
                  The
                  {{
                    district.noun.charAt(0).toUpperCase() +
                      district.noun.slice(1)
                  }}
                  of {{ district.name }},
                  {{ district.state }}
                </h2>
              </div>
              <p class="text-white text-s hover:cursor-pointer underline mt-0">
                <router-link
                  active-class="active"
                  :to="`/district/${district.id}`"
                  class="text-white text-s hover:cursor-pointer hover:text-gray-200 underline"
                >
                  {{ district.declaredChariteers.length }} volunteers
                </router-link>
              </p>
            </div>
            <div class="charityListFlexbox">
              <CharityCard
                v-for="charity in providersInSpecificDistrict(district.id)"
                v-bind:key="charity.id"
                v-bind:charity="charity"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import gql from "graphql-tag";
import Skeleton from "primevue/skeleton";
import { watch } from "vue";
import { useStore } from "vuex/dist/vuex.cjs";
import { useQuery, useMutation } from "@vue/apollo-composable";

import CharityCard from "@/components/CharityCard.vue";
import TopNav from "@/components/TopNav.vue";
import FDFullWidthBanner from "../components/advertisements/FDFullWidthBanner.vue";
import Footer from "@/components/Footer.vue";
import LikeButton from "../components/buttons/LikeButton.vue";

import "primevue/resources/themes/saga-blue/theme.css"; // Theme
import "primevue/resources/primevue.min.css"; // Core CSS

// Charity List is the root View of all of the web functions
// Here, we click on CharityCards to view their QuestList

export default {
  name: "CharityList",
  setup() {
    console.log("We're in Charity List");
  },
  data() {
    return {
      id: 0,
      providers: [],
      districts: [
        {
          id: 1,
          name: "Hoboken No Joking At All, NJ"
        },
        {
          id: 2,
          name: "Jersey City, NJ"
        },
        {
          id: 3,
          name: "West New York, NJ"
        },
        {
          id: 4,
          name: "Bayonne, NJ"
        },
        {
          id: 5,
          name: "Weehawken, NJ"
        },
        {
          id: 11,
          name: "The Bronx, NY"
        },
        {
          id: 12,
          name: "Staten Island, NY"
        },
        {
          id: 100,
          name: "Waltham, MA"
        }
      ]
    };
  },
  components: {
    CharityCard,
    TopNav,
    FDFullWidthBanner,
    Footer,
    Skeleton,
    LikeButton
  },
  setup() {
    const {
      result: districtList,
      loading: districtLoading,
      error: districtError
    } = useQuery(gql`
      {
        districts {
          id
          name
          acronym
          county
          state
          noun
          centerLatitude
          centerLongitude
          zoomLevel
          declaredChariteers {
            id
            username
          }
        }
      }
    `);

    const store = useStore();
    const playerId = store.state?.playerProfile?.masterPlayerID || "";
    const {
      result: favDistricts,
      loading: favLoading,
      error: favError,
      refetch: refetchFavDistricts
    } = useQuery(gql`
          query GetChariteerById($id: String!) {
            chariteer (id: $id) {
              favoriteDistricts {
                id
                name
              }
            }
          }
        `,
        { id: playerId }
      );
    watch(
      () => store.state?.playerProfile?.masterPlayerID,
      (newPlayerId) => {
        if (newPlayerId) {
          refetchFavDistricts({ id: newPlayerId });
        }
      }
    );
    return {
      districtList,
      districtLoading,
      districtError,
      favDistricts,
      favLoading,
      favError,
      refetchFavDistricts
    };
  },
  methods: {
    openSponsorWebsite() {
      var tagsObj = {
        messageType: "Chariteer Clicks Sponsor's Banner In Charity List"
      };
      this.$store.dispatch("clickTrackLogForAmazon", tagsObj);
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSeDqGAD_QYa26V4OjzmLuJ3ho87qpfysxv_kUme4xUJCIOCsQ/viewform"
      );
    },
    providersInSpecificDistrict(districtID) {
      return this.$store.state.providers.filter(
        item => item.district == districtID
      );
    },
    districtHasCharities(id) {
      return this.providersInSpecificDistrict(id).length > 0;
    },
    districtIsFavorite(id) {
      return this.favoriteDistrictIds.includes(id);
    },
    handleLikeClick(id) {
      if (
        !this.favoriteDistrictIds.includes(id) &&
        this.favoriteDistrictIds.length >= 2
      ) {
        alert("You can only favorite up to 2 districts");
      } else {
        this.mutationFavoriteDistrict(id);
      }
    },
    mutationFavoriteDistrict(id) {
      let UCIFavDistricts = [...this.favoriteDistrictIds];
      if (UCIFavDistricts.includes(id)) {
        UCIFavDistricts = UCIFavDistricts.filter(district => district !== id);
      } else {
        UCIFavDistricts.push(id);
      }

      const variables = {
        updateChariteerInput: {
          id: this.$store.state.playerProfile.masterPlayerID,
          favoriteDistrictsList: UCIFavDistricts
        }
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateChariteer(
              $updateChariteerInput: UpdateChariteerInput!
            ) {
              updateChariteer(updateChariteerInput: $updateChariteerInput) {
                id
                nameFirst
                favoriteDistricts {
                  id
                  name
                  acronym
                  county
                  state
                  noun
                  centerLatitude
                  centerLongitude
                  zoomLevel
                }
              }
            }
          `,
          variables
        })
        .then(({ data }) => {
          this.refetchFavDistricts(); // Ensure you have a method or logic to refetch or update UI accordingly
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  computed: {
    favoriteDistrictIds() {
      if (!this.favDistricts) {
        return [];
      }
      return this.favDistricts.chariteer.favoriteDistricts.map(
        district => district.id
      );
    },
    favoriteDistrictFull() {
      let favDistrictFull = [];
      this.districtList.districts.forEach(district => {
        if (this.favoriteDistrictIds.includes(district.id)) {
          favDistrictFull.push(district);
        }
      });
      return favDistrictFull;
    }
  }
};
</script>

<style scoped>
.charityList {
  background-color: #ffa94e;
  margin-top: 0px;
  min-height: 100vh;
}

h1 {
  color: white;
  margin-top: 100px;
}

h2 {
  color: white;
  margin-top: 5vh;
}

.charityListFlexbox {
  background-color: rgba(0, 128, 0, 0);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 25h;
  padding-top: 30px;
}

.heroSponsorButton {
  background-color: #ffa94e;
  max-height: 256px;
  border-width: 0px;
  margin-top: 5vh;
  margin-bottom: 15vh;
  padding: 0px;
  cursor: pointer;
}

.heroSponsorImage {
  max-height: 256px;
  width: 50vw;
}

.favorite-icon {
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
}
</style>

<template>
  <div class="splash-page">
    <TopNav/>
    <FDFullWidthBanner v-show="false"/>
    <SplashBasic/>
    <!--
    <stripe-pricing-table pricing-table-id="prctbl_1OB1o5B7fbVjG4pW5arpvUGI"
      publishable-key="pk_live_SjLJjGfP8tSDnMaJ3IvsmXg2">
    </stripe-pricing-table>
    -->
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNav from "@/components/TopNav.vue";
import FDFullWidthBanner from "../components/advertisements/FDFullWidthBanner.vue";
import SplashBasic from "@/components/SplashBasic.vue";
import Footer from "@/components/Footer.vue";
// import * as grongus from 'https://js.stripe.com/v3/pricing-table.js'

export default {
  name: "Noob",
  components: {
    TopNav,
    FDFullWidthBanner,
    SplashBasic,
    Footer
  },
  created() {
    // console.log("Route(r)s?")
    // console.log(this.$router)
    // console.log(this.$route)

    // Queries are always casted as strings
    if ( this.$route.query.forceReload == "true" )
    {
      // console.log("Reload!!!!!!!!!!!!!1111")
      // this.$route.query.forceReload = "false"
      window.history.pushState({}, null, "/");
      window.location.reload(true);
    }
    else
    {
      // console.log("Went to ELSE")
    }
  }
};
</script>

<style scoped>
.splash-page {
  background-color: rgba(0, 128, 0, 0.0);
  height: 100%;
  margin: 0px;
}

p {
  margin: 0px;
}

</style>

import { createRouter, createWebHistory } from "vue-router";
import { useCookies } from 'vue3-cookies';

import store from '../store';
import { getLocalStorageItem } from '@/utils/localstorage';
import Splash from "../views/Splash.vue";
import Subscribe from "../views/Subscribe.vue";
import Noob from "../views/Noob.vue";
import LinkMenu from "../views/LinkMenu.vue";
import AccountRemoval from "../views/AccountRemoval.vue";
import Home from "../views/Home.vue";
import CreateAccount from "../views/CreateAccount.vue";
import CreateChariteerAccount from "../views/CreateChariteerAccount.vue";
import CreateProviderAccount from "../views/CreateProviderAccount.vue";
import Login from "../views/Login.vue";
import PasswordReset from "../views/PasswordReset.vue";
import CharityList from "../views/CharityList.vue";
import QuestList from "../views/QuestList.vue";
import OSVQuestDetails from "../views/OSVQuestDetails.vue";
import ApplicationDetails from "../views/ApplicationDetails.vue";
import ProviderQuestDetails from "../views/ProviderQuestDetails.vue";
import ProviderPastQuestDetails from "../views/ProviderPastQuestDetails.vue";
import Calendar from "../views/Calendar.vue";
import Quests from "../views/Quests.vue";
import Chariteers from "../views/Chariteers.vue";
import Groups from "../views/Groups.vue";
import Guilds from "../views/Guilds.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TermsOfService from "../views/TermsOfService.vue";
import EmailVerified from "../views/EmailVerified.vue";
import PageNotFound from "../views/PageNotFound.vue";
import GuildDetails from "../views/GuildDetails.vue";
import Contest from "../views/Contest.vue";
import District from "../views/District.vue";
import OnTheWay from "../views/OnTheWay.vue";

const { cookies } = useCookies();

// Function to handle login with PlayFab credentials
const handleLogin = async (email, password) => {
  window.PlayFab.settings.titleId = 'FEAAC';
  const loginRequest = {
    Email: email,
    Password: password,
  };
  
  window.PlayFabClientSDK.LoginWithEmailAddress(loginRequest, LoginWithEmailAddressCallback);
};

// Function to handle the callback from PlayFab login
const LoginWithEmailAddressCallback = async (result, error) => {
  if (result) {
    try {
      cookies.set('username', 'Grongus');
      await store.dispatch('generatePlayerProfile', result.data.PlayFabId);
      await store.dispatch('recordPlayerEmailAddress', getLocalStorageItem('email'));
      await store.dispatch('recordPlayerEntityInformation', result.data.EntityToken);

      // Trigger additional downloads
      await store.dispatch('downloadPlayerInventory');
      await store.dispatch('downloadPlayerGroupMemberships');
      // Check if Chariteer exists in the database
      await store.dispatch('checkIfChariteerIsInDatabase', result.data.PlayFabId);

      const returnTo = cookies.get('returnTo');
      if (returnTo) {
        cookies.remove('returnTo');
        router.push(returnTo);
      }
    } catch (error) {
      console.error('Login callback error:', error);
      router.push('/login');
    }
  } else {
    console.error('Login failed:', error);
    router.push('/login');
  }
};

const routes = [
  {
    path: "/",
    name: "Splash",
    component: Splash,
    meta: { public: true }
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    component: Subscribe,
    meta: { public: true }
  },
  {
    path: "/noob",
    name: "Noob",
    component: Noob,
    meta: { public: false }
  },
  {
    path: "/link-menu",
    name: "LinkMenu",
    component: LinkMenu,
    meta: { public: true }
  },
  {
    path: "/account-removal",
    name: "Account Removal",
    component: AccountRemoval,
    meta: { public: true }
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { public: false }
  },
  {
    path: "/createAccount",
    name: "CreateAccount",
    component: CreateAccount,
    meta: { public: false }
  },
  {
    path: "/createChariteerAccount",
    name: "CreateChariteerAccount",
    component: CreateChariteerAccount,
    meta: { public: true }
  },
  {
    path: "/createProviderAccount",
    name: "CreateProviderAccount",
    component: CreateProviderAccount,
    meta: { public: true }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { public: true }
  },
  {
    path: "/passwordReset",
    name: "PasswordReset",
    component: PasswordReset,
    meta: { public: true }
  },
  {
    path: "/charityList",
    name: "CharityList",
    component: CharityList,
    meta: { public: false }
  },
  {
    path: "/questList/:providerID",
    name: "QuestList",
    component: QuestList,
    props: true,
    meta: { public: true }
  },
  {
    path: "/questList/:providerID/quest/:id",
    name: "OSVQuestDetails",
    component: OSVQuestDetails,
    props: true,
    meta: { public: true }
  },
  {
    path: "/quest/:id",
    name: "ApplicationDetails",
    component: ApplicationDetails,
    props: true,
    meta: { public: true }
  },
  {
    path: "/quest/:id",
    name: "ProviderQuestDetails",
    component: ProviderQuestDetails,
    props: true,
    meta: { public: false }
  },
  {
    path: "/quest/:id",
    name: "ProviderPastQuestDetails",
    component: ProviderPastQuestDetails,
    props: true,
    meta: { public: false }
  },
  {
    path: "/district/:id",
    name: "DistrictDetails",
    component: District,
    props: true,
    meta: { public: false }
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
    meta: { public: false }
  },
  {
    path: "/quests",
    name: "Quests",
    component: Quests,
    meta: { public: true }
  },
  {
    path: "/chariteers",
    name: "Chariteers",
    component: Chariteers,
    meta: { public: false }
  },
  {
    path: "/chariteers/:chariteerID",
    name: "ChariteerDetails",
    component: OnTheWay,
    meta: { public: false }
  },
  {
    path: "/groups",
    name: "Groups",
    component: Groups,
    meta: { public: false }
  },
  {
    path: "/guilds",
    name: "Guilds",
    component: Guilds,
    meta: { public: false }
  },
  {
    path: "/guilds/:guildID",
    name: "GuildDetails",
    component: GuildDetails,
    props: true,
    meta: { public: true }
  },
  {
    path: "/contest",
    name: "Contest",
    component: Contest,
    meta: { public: true }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: { public: true }
  },
  {
    path: "/privacy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    meta: { public: true }
  },
  {
    path: "/terms",
    name: "Terms of Service",
    component: TermsOfService,
    meta: { public: true }
  },
  {
    path: "/verifiedEmail",
    name: "Email Verified",
    component: EmailVerified,
    meta: { public: true }
  },
  // catchall 404
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { public: true }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from) => {
  if (to.meta.public) {
    return true;
  } else {
    const email = getLocalStorageItem('email');
    const pwd = getLocalStorageItem('pwd');
  
    if (email && pwd) {
      try {
        await handleLogin(email, pwd);
      } catch (error) {
        console.error('Login Error: ', error);
        return { name: "Login"}
      }
      return true;
    } else {
      return { name: "Login" }
    }
  }
})

export default router;

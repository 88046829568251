<template>
  <div class="groupMembersBox">

    <div class="groupMembers">

      <!-- What if the data hasn't been downloaded yet, or there's 0 members? -->
      <template v-if="$store.state.currentlyViewedGroupMembers.length == 0">
        <p>This group has no members.</p>
      </template>

      <template v-else-if="$store.state.currentlyViewedGroupMembers.length > 0">
        <p>There are {{ $store.state.currentlyViewedGroupMembers.length }} discrete Roles.</p>
        <!-- For each thing in currentlyViewedGroupMembers we're going to make a little category and then show those members -->
        <h3 v-for="(roleGroup, roleGroupIndex) in $store.state.currentlyViewedGroupMembers" :key="roleGroup.RoleId">
          {{ roleGroup.RoleName + " — " + roleGroup.Members.length }}

          <ul>
            <li v-for="(member, index) in roleGroup.Members" :key="member.Key.Id" class="groupMemberRow">
              {{ member.firstName + " " + member.lastName }}
              
              <select name="change-role-dropdown" class="change-role-dropdown" v-on:change="changeRole(roleGroup.RoleId, member.Key, $event.target.value, roleGroupIndex, index)">
                <option value="" selected disabled hidden>Change Role</option>
                <option v-for="roleGroup in $store.state.currentlyViewedGroupMembers" :key="roleGroup.RoleId" :value="roleGroup.RoleId">{{ "Change to " + roleGroup.RoleName }}</option>

                <!--
                <option value="admins">Administrator</option>
                <option value="members">Member</option>
                <option value="noobs">Noob</option>
                -->
              </select>
            </li>
          </ul>

        </h3>
      </template>
      
    </div>

  </div>
</template>

<script>
export default {
  name: "GroupMembers",
  data() {
    return {
      somekindafilterinfo: ''
    }
  },
  methods: {
    methodName ( parameter )
    {
      console.log("Sweet parameter: " + parameter)
    },
    changeRole ( currentRoleId, playerEntityKey, newRoleId, roleIndex, memberIndex )
    {
      if ( currentRoleId != newRoleId )
      {
        console.log("Change " + playerEntityKey.Id + " from " + currentRoleId + " to " + newRoleId)
        console.log("This user is located within Role " + roleIndex + " at Index " + memberIndex)
        // console.log(playerEntityKey.Type)
        // mash everything into an object sonnnn

        var bundle = {
          singleUserEntityKey: playerEntityKey,
          originRoleID: currentRoleId,
          destinationRoleID: newRoleId,
          originRoleIndex: roleIndex,
          memberIndex: memberIndex
        }

        this.$store.dispatch('changeGroupRoleForSingleUser', bundle)
      }
      else if ( currentRoleId == newRoleId )
      {
        console.log("Don't make any changes. " + playerEntityKey.Id + " is already in " + currentRoleId + ", no need to change Role to " + newRoleId)
      }

      
    },
  },
  created() {
    console.log("Group Members active. Fetching members for " + this.$store.state.groupMemberships[0].GroupName + " (Group " + this.$store.state.groupMemberships[0].Group.Id + ")")

    // This is only set up for 1 group right now because I would need to do more work if someone belonged to
    // multiple groups and they wanted to see all the info for one of those specific groups. So if you're in
    // more than one we're just interacting with whatever is in the [0] slot.
    this.$store.dispatch('downloadGroupMembers', this.$store.state.groupMemberships[0].Group)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.groupMembersBox {
  background-color: #00000000;
  width: 800px;
  padding: 0px;
  margin: 0px auto;         /* Set the box to be centered */
}

p {
  text-align: justify;
  margin: 0px;
}

ul {
  margin: 0px;
  padding: 0px;
}

.groupMembers {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  background-color: #ffffff;
  text-align: left;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.25);
}

.groupMemberRow {
  display: flex;
  justify-content: space-between;

  font-size: 75%;
  font-weight: 400;
  list-style: none;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.15);
}



@media only screen and (max-width: 600px) {
  .groupMembersBox {
    width: 100%;
  }
}
</style>

<template>
  <div class="splashBasic">
    <div class="grid lg:grid-cols-2 grid-cols-1 px-20 py-10 gap-8">

      <!-- Big Logo and a description of the app. -->
      <div class="leftInfoBox">
        <div class="leftInfoBox-verticalCenterer">
          <!-- Beeg Logo -->
          <img class="hcq-logo" alt="Charity Quest logo" src="../assets/CQ-Logo.png" />

          <!-- Your choices -->
          <div class="text-white text-left mx-12">
            <p>Your Charity Quest account gets you access to one-tap volunteering right where you live! After you create an account, take a look at each charity's listings to see which shifts you're able to attend. Once you find a time that works for you, sign up and the charity will be able to contact you. You can always drop out if the time no longer works for you. Create an account to get started!</p>
            <p>The best experience is on mobile. Search for Charity Quest on your phone's app store to download it for free. Current service market is New Jersey, The Bronx, and Staten Island.</p>
          </div>
          <div class="mx-12">
            <button
                class="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 mx-auto rounded border-none"
                @click="
                  e => {
                    navigateDirectlyToCreateProviderAccount(e);
                  }
                "
              >
                <img
                  class="w-4 h-4 mr-2"
                  alt="A little pencil, meant for filling out a survey."
                  src="../assets/navicons/CQ-Navicon_Pencil.png"
                />
                <span>Looking to add your charity to our network instead? Click here!</span>
            </button> 
          </div>
        </div>
      </div>


      <!-- Two big images of the app running on phones. -->
      <div class="mobileAppImageBox">
          <img class="mobileAppImage" alt="The Charity Quest Map" src="../assets/phone-splash-01.png" />
          <img class="mobileAppImage" alt="Sample Quest" src="../assets/phone-splash-02.png" />
      </div>

    </div>



    <div class="absolute top-0 left-0 z-50 text-white opacity-0 pointer-events-none">
      <p>Hullo</p>
        <ApolloQuery
          :query="gql => gql`
            query GetQuestById ($id: Int!) {
              quest (id: $id) {
                id
                eventTitle
                description
                resourceMaxNumber
              }
            }
          `"
          :variables="{ id }"
        >
          <template v-slot="{ result: { loading, error, data } }">
            <!-- Loading -->
            <div v-if="loading" class="loading apollo">Loading...</div>

            <!-- Error -->
            <div v-else-if="error" class="error apollo">An error occurred</div>

            <!-- Result -->
            <div v-else-if="data" class="result apollo">Here's the data: {{ data }}</div>

            <!-- No result -->
            <div v-else class="no-result apollo">No result :(</div>
          </template>
        </ApolloQuery>
    </div>

  </div>

  


</template>

<script>


export default {
  name: "SplashBasic",
  data() {
    return {
      id: 2
    }
  },
  methods: {
    navigateDirectlyToCreateChariteerAccount() {
      this.$router.push('/createChariteerAccount')
    },
    navigateToLogIn() {
      this.$router.push('/login')
    },
    navigateDirectlyToCreateProviderAccount() {
      console.log("Let's go to the other page.");
      this.$router.push({
        name: "CreateProviderAccount"
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.splashBasic {
  background-color: #FFA94E;
  width: 100%;
  /* height: 80vh; */
  margin: 0px auto;
  /* padding-top: 50px; */
}

.hcq-logo {
  width: 100%;
  max-width: 720px;
  height: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
  object-fit: cover;
  background: #ff000000;
}

h2 { 
  color: white;
  margin: 0 auto;
  width: 400px;
}

/* Isn't splashBasic the content wrapper? */
.content-wrapper {
  background-color: rgb(0, 0, 0, 0);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  /* height: 70vh; */
  padding-top: 5vh;
}

/* Should be on the left in wide screen mode, on top for mobile.*/
.leftInfoBox {
  background-color: rgba(144, 238, 144, 0.0);
  display: flex;
  order: 2;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.leftInfoBox-verticalCenterer {
  height: 100%;
  background-color: rgba(200, 238, 144, 0.0);
}

.leftInfoBoxText {
  /* height: 30%; */
  background-color: red;
  font-size: calc(45% + 0.5vw)
}

/* Should be on the right in wide screen mode, on bottom for mobile.*/
.mobileAppImageBox {
  display: flex;
  order: 4;
  justify-content: center;
  align-items: center;

  width: 100%;
}


.mobileAppImage {
  width: 50%;
}



.leftInfoBoxText {
  color: white;
  background-color: #00000000;
  font-weight: 500;
  margin: 0 auto;
  width: 85%;
}




/* Use a media query to add a breakpoint at 800px:
@media screen and (max-width: 800px) {
  .splashBasic {
    height: 200vh;
  }

  .content-wrapper {
    background-color: rgba(251, 255, 10, 0.0);
    flex-wrap: wrap;
  }

  .leftInfoBox {
    height: 100vh;
    width: 100vw;
  }

  .mobileAppImageBox {
    height: 100vh;
    width: 100vw;
  }

  .left-space, .middle-space, .right-space {
    width: 0vw;
  }

  .leftInfoBoxText {
    font-size: calc(75% + 0.5vw)
  }
}

*/


</style>

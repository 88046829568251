<template>
  <div class="createAccount">
    <h1>Three choices: chariteer, provider, sponsor. Need 1 route and 2 dead buttons</h1>

    <!-- Route to Create Chariteer Account -->
    <router-link
      class="create-chariteer-account-link"
      :to="{ name: 'CreateChariteerAccount' }"
    >
      <div class="button-navigate-to-create-chariteer-account">
        <h4>Chariteer</h4>
      </div>
    </router-link>

    <h4>Provider</h4>
    <h4>Sponsor</h4>
  </div>
</template>

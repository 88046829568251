<template>
  <div id="page-container">
    <TopNav/>
    <FDFullWidthBanner/>
    <div class="createChariteerAccount">

      <h1 class="text-center">Create your account</h1>
      <CreateChariteerAccountForm/>
      
    </div>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNav from "@/components/TopNav.vue";
import FDFullWidthBanner from "../components/advertisements/FDFullWidthBanner.vue";
import CreateChariteerAccountForm from "@/components/CreateChariteerAccountForm.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "CreateChariteerAccount",
  components: {
    TopNav,
    FDFullWidthBanner,
    CreateChariteerAccountForm,
    Footer
  }
};
</script>

<style scoped>

#page-container {
  background-color: #FFA94E;
  position: relative;
  min-height: 100vh;
}

h1 {
  color: white;
  margin-top: 50px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 50px;
}

.createChariteerAccount {
  padding-bottom: 150px;    /* This is for the footer */
  margin: 0px auto;         /* Set the app to be centered */
}

@media only screen and (max-width: 650px) {
  .createChariteerAccount {
    width: 100%;
  }
}
</style>

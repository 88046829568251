<template>
	<div v-if="showModal" class="overlay" @click="closeModal">
		<div
			class="modal-content bg-white rounded-2xl flex flex-col mx-auto"
			@click.stop
		>
			<slot name="header">
				<h1>{{ data?.name }}</h1>
			</slot>
			<slot name="body">
				<p>{{ data?.description }}</p>
				<div class="flex justify-center">
					<router-link
						class="bg-green-300 text-xl rounded-xl shadow-lg px-5 py-2 border-none hover:cursor-pointer hover:bg-green-400 transition-colors duration-300 ease-in-out mb-5 no-underline text-black w-40"
						:to="{
							name: 'GuildDetails',
							params: { guildID: data?.id },
						}"
					>
						Guild Details
					</router-link>
				</div>
			</slot>
			<slot name="footer">
				<button @click="closeModal" class="w-40 inline-block">
					Close
				</button>
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		showModal: {
			type: Boolean,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
	},
	methods: {
		closeModal() {
			this.$emit("close");
		},
	},
};
</script>

<style>
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-content {
	width: 70%;
	padding: 20px;
	position: relative;
	background: #fff;
}
</style>

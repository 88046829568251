<template>
  <div class="page-not-found">
    <TopNav/>
    <div class="error-message">
      <h2>404 Error: Page Not Found</h2>
      <h3>Sorry, the page you're looking for could not be found.</h3>
      <p>You may have to log in again to complete whatever you were doing,<br>if you got kicked out to here. My apologies!<br>I am working on fixing that...</p>
    </div>
    <Footer/>
  </div>
</template>


<script>
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "CreateChariteerAccount",
  components: {
    TopNav,
    Footer
  }
};
</script>

<style scoped>

.page-not-found {
  background-color: #FFA94E;
}

.error-message {
  margin-top: 20vh;
  margin-bottom: 25vh;
}

</style>
<template>
  <Teleport to="#modalScreen">
    <div class="fixed top-0 left-0 w-screen h-screen z-80">
      <!--
            Cool idea: what if this modal could take a Quest OR NOT and so it serves two purposes:
            a modal you would use to give someone more credit for a quest, but also a modal you would
            use if you were browing your list of volunteers and selected someone as "being really creepy"
        -->

      <!-- Black Background -->
      <div
        name="Blackground"
        class="bg-black opacity-75 w-screen h-screen absolute top-0 left-0"
        @click="() => close()"
      />

      <!-- The Pop Up -->
      <div
        class="bg-white w-1/2 z-90 mx-auto top-20 relative rounded shadow-lg py-5 px-5"
      >
        <div class="flex flex-row-reverse">
          <button
            class="bg-gray-200 hover:bg-gray-300 rounded-full p-2 px-3 border-none"
            @click="() => close()"
          >
            ✕ Close
          </button>
        </div>
        <h1 class="text-center">
          {{ chariteerData.nameFirst + " " + chariteerData.nameLast }}
        </h1>
        <p class="text-center mx-10">
          This window lets you take more advanced actions beyond simple
          pass/fail attendance.
        </p>

        <!-- The Buttons -->
        <!-- TO DO: Award Bonus Time, for rewarding a volunteer who was early or stayed late -->
        <!-- TO DO: Subtract Time, for penalizing a volunteer who was late or left early -->
        <!-- TO DO: Flag, for reporting a serious problem that Frank DiCola must investigate -->
        <!-- Boot, for removing this volunteer from this quest only -->
        <div>
          <h3 class="m-0">Boot from this Quest</h3>
          <p class="text-sm italic mt-0">
            Sometimes, volunteers sign up for two overlapping events and need to
            be manually removed. You can boot this person from this quest, and
            it won't affect their ability to sign up for anything else in the
            future.
          </p>
          <div class="flex">
            <div class="flex items-center h-5">
              <input
                v-model="option"
                id="boot-option"
                aria-describedby="boot-chariteer"
                type="radio"
                value="BOOT"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
            <div class="ms-2 text-sm">
              <label for="boot-option" class="font-medium"
                >Boot {{ chariteerData.nameFirst }} from
                {{ questData.eventTitle }}</label
              >
              <p id="boot-chariteer" class="text-xs font-normal mt-0">
                {{ chariteerData.nameFirst }} will be removed from this Quest and immediately notified with an email.
              </p>
            </div>
          </div>
          <div v-if="option == 'BOOT'">
            <div class="questionbox">
              <p class="font-bold text-left">
                Please provide a reason for booting this volunteer from this
                quest.
              </p>
              <textarea
                v-model="bootMessage"
                class="w-full h-20 rounded-md"
                :class="{
                  'border-red-500': errorBootMessage,
                  'border-1': errorBootMessage
                }"
                @input="validateBootMessage"
              />
              <div class="self-end">
                <p class="text-xs">
                  {{ this.bootMessage.length }} / 150 characters
                </p>
              </div>
            </div>
          </div>
          <p class="text-sm italic mt-0">
            However, if you notice a pattern of abusive behavior, please let me
            know at frank@charityquest.io because this person may need to be
            removed from the network.
          </p>
        </div>
        <!-- TO DO: Ban, for preventing this volunteer from signing up for anything at this charity ever again -->

        <!-- The buttons -->
        <div class="mx-auto w-2/3 grid grid-cols-1 space-x-10">
          <button
            v-if="isSubmitButtonActive"
            class="bg-green-500 hover:bg-green-400 cursor-pointer text-white font-bold py-4 px-4 rounded border border-green-100"
            @click="() => handleSubmitChanges(changesObject)"
          >
            Submit Changes
          </button>
          <button
            v-else
            class="bg-gray-500 cursor-not-allowed text-white font-bold py-4 px-4 rounded border border-green-100"
            disabled
          >
            Choose an option or close this window.
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { Teleport } from "vue";
import { ref } from "vue";

export default {
  props: ["questData", "contractData", "handleSubmitChanges", "close"],
  components: {
    Teleport
  },
  data() {
    return {
      option: "",
      bootMessage: "",
      banMessage: null,
      errorBootMessage: false
    };
  },
  setup(props) {
    // console.log(
    //   "Showing More Options for Chariteer panel... how do we pick someone?"
    // );
    // console.log(props);
    // console.log(props.close);

    return {};
  },
  methods: {
    validateCodeInput() {
      // Ensure that everything is capitalized
      this.textarino = this.textarino.toUpperCase();
    },
    validateBootMessage() {
      if (this.bootMessage.length > 150) {
        return (this.errorBootMessage = true);
      }
      return (this.errorBootMessage = false);
    }
  },
  computed: {
    isSubmitButtonActive() {
      //   console.log(this.option);
      if (this.option !== "" && this.errorBootMessage == false) {
        // We plan to boot this volunteer, at least
        return true;
      }

      return false;
    },
    changesObject() {
      //   console.log("contract", this.contractData);
      let returnObj = {
        contractId: this.contractData.id,
        option: this.option,
        bootMessage: this.bootMessage,
        banMessage: this.banMessage,
        supervisorId: this.$store.state.playerProfile.masterPlayerID
      };
      // Let's check if we actually changed the value before stuffing it into this
      // object down here. The "id", set above, is always there of course.
      //   console.log(returnObj);
      if (this.option == "BOOT") {
        returnObj.bootMessage = this.bootMessage;
      }
      return returnObj;
    },
    chariteerData() {
      return this.contractData.chariteer;
    }
  }
};
</script>

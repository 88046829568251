<template>
  <div class="passwordReset">
    <TopNav/>
    <PasswordResetForm/>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import PasswordResetForm from "@/components/PasswordResetForm.vue";
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "PasswordReset",
  components: {
    PasswordResetForm,
    TopNav,
    Footer
  }
};
</script>

<style scoped>
.passwordReset {
  background-color: #FFA94E;
  margin: 0px;
}
</style>

<template>
  <button class="charityCard" v-on:click="onClick">
    <!-- We used to check if charity.isVisible but that's dumb so I removed it. Chesterton's fence? -->
    <div>
      <h4>{{ charity.ManagedProviderName }}</h4>
    </div>
  </button>

</template>

<script>
export default {
  name: "ManagedCharityCard",
  props: {
    charity: Object
  },
  methods: {
    onClick() {
      console.log("This Provider is " + this.charity.ManagedProviderName)


      var tagsObj = {
        messageType: "Chariteer Clicks Charity To View All Quests",
        charityName: this.charity.ManagedProviderName,
        charityID: this.charity.ManagedProviderId
      }
      //this.$store.dispatch('clickTrackLogForAmazon', tagsObj)



      
      // Is this an old PlayFab Quest or is it a new, cool, Database Quest?
      if ( this.charity.providerID != undefined )
      {
        // Old and broke
        console.log("The Provider ID is " + this.charity.providerID);
        this.$router.push({ name: 'QuestList', params: {providerID: this.charity.providerID}});
      }
      else if ( this.charity.id != undefined )
      {
        // New and woke!
        console.log("Inspecting Database Provider " + this.charity.name + " (" + this.charity.id + ")");
        this.$router.push({ name: 'QuestList', params: {providerID: this.charity.id}});
      }
      else if ( this.charity.ManagedProviderId != undefined )
      {
        // Manager Account
        console.log("Changing context to Provider " + this.charity.ManagedProviderName + " (" + this.charity.ManagedProviderId + ")");
        // change the context object
        this.$router.push({ name: 'QuestList', params: { providerID: this.charity.ManagedProviderId } });
      }
      else
      {
        console.log("Error with Provider " + this.charity.name);
      }
      
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.charityCard {
  width: 250px;
  cursor: pointer;
  color: #FFA94E;
  font-family: Palanquin, Montserrat, Helvetica, Arial, sans-serif;
  font-size: 110%;
  background-color: white;
  border: 3px solid white;
  border-radius: 10px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 20px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}
.charityCard:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.5);
}
</style>

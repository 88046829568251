import { createApp, provide, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import VueApolloComponents from "@vue/apollo-components";
import { createApolloProvider } from "@vue/apollo-option";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { ApolloLink, concat, split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/aura-light-amber/theme.css";

// Google Ads
import ScriptX from "vue-scriptx";
import Ads from "vue-google-adsense";

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  // console.log("Local Access Token: " + localStorage.getItem('accessToken'));
  const token = localStorage.getItem("accessToken");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ""
    }
  });
  return forward(operation);
});

const httpLink = new HttpLink({ uri: process.env.VUE_APP_DATABASE_URL });

export const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache()
});

// METHOD #1

//const cache = new InMemoryCache()

// const apolloClient = new ApolloClient({
//   cache,
//   uri: process.env.VUE_APP_DATABASE_URL,
// })

console.log("Environment Variable: " + process.env.VUE_APP_DATABASE_URL);

// Local - http://localhost:3000/graphql
// Staging - https://charity-quest-service.fly.dev/graphql
// Production - https://api.charityquest.io/graphql

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient
});

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App)
})
  .use(store)
  .use(router)
  .use(apolloProvider)
  .use(VueApolloComponents)
  .use(ScriptX)
  .use(Ads.Adsense)
  .use(Ads.InArticleAdsense)
  .use(Ads.InFeedAdsense)
  .use(PrimeVue)
  .mount("#app");

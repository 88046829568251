<template>
    <!--    
        THIS MODAL is designed to appear after you sign up to volunteer for a Quest.
        It's a confirmation window saying that you're signed up.
        But it's also going to direct people to one of two payment options:
            - tip me (one-time) via Stripe
            - subscribe via Stripe
    -->

    <Teleport to="#modalScreen">
    <div class="fixed top-0 left-0 w-screen h-screen z-80">
        <!-- Black Background -->
        <div name="Blackground" class="bg-black opacity-75 w-screen h-screen absolute top-0 left-0" @click="() => close()" />
        
        <!-- The Pop Up -->
        <div class="bg-white w-4/5 lg:w-3/4 md:w-3/4 z-90 mx-auto top-5 relative rounded py-5 px-5 overflow-y-auto max-h-screen">
        
            <!-- The Close Button -->
            <div class="flex flex-row-reverse">
                <button
                    class="bg-gray-200 hover:bg-gray-300 rounded-full p-2 px-3 border-none"
                    @click="() => close()"
                >
                    ✕ Close
                </button>
            </div>

            <!-- The Content -->
            <h1 class="text-center"> Thanks for signing up to volunteer! </h1>
            <!-- <h3 class="text-center font-normal">You're all set. <span class="font-bold">{{ quest.provider.name }}</span> is expecting you to come to <span class="font-bold">{{ quest.location.name }}</span> promptly at <span class="font-bold">{{ questStartTimeDisplay }}</span> and stay until dismissed by a supervisor. <span class="font-bold">{{ quest.giverName }}</span> will take attendance on-site, which will be saved to your account and can be used for reporting to school & work.</h3>-->
            <h3 v-if="quest.giverName != ''" class="text-center font-normal">You're all set. <span class="font-bold">{{ quest.provider.name }}</span> is expecting you to come to <span class="font-bold">{{ quest.location.name }}</span> on time and stay until dismissed by a supervisor. <span class="font-bold">{{ quest.giverName }}</span> will take attendance on-site, which will be saved to your account and can be used for reporting to school & work.</h3>
            <h3 v-else class="text-center font-normal">You're all set. <span class="font-bold">{{ quest.provider.name }}</span> is expecting you to come to <span class="font-bold">{{ quest.location.name }}</span> on time and stay until dismissed by a supervisor. The staff will take attendance on-site, which will be saved to your account and can be used for reporting to school & work.</h3>


            <!-- Guilt Trip-->
            <div class="bg-purple-600 text-white p-1 rounded">
                <h2 class="text-center">How Charity Quest Works</h2>
                <div class="flex flex-col lg:flex-row md:flex-row">
                    <img
                        class="fill-current w-55 h-55 md:w-72 md:h-72 lg:h-72 lg:h-72  mr-2 pl-2"
                        alt="Handrawn picture of Frank, CEO of Charity Quest"
                        src="../../assets/portraits/2024-Frank-Portrait.png"
                    />
                    <div class="m-2 text-center">
                        <p class="m-0">Thank you so much for using my volunteer network! My name is Frank, and I'm the founder of Charity Quest. I'm on a mission to make it easier to volunteer in your neighborhood! <span class="font-bold">Did you know this platform is offered 100% free</span> to all of the local charities you see here? We also provide them the following free services on request:</p>
                        <p class="m-0 pt-2 indent-4">• Content Writing for Volunteer Descriptions</p>
                        <p class="m-0 indent-4">• Specialty Volunteer Recruitment</p>
                        <p class="m-0 indent-4">• IT & Software Consulting</p>
                        <p class="m-0 pt-2">If Charity Quest helped you volunteer locally, please consider offsetting the costs of our cloud computing by choosing an option below! <span class="font-bold">Thank you so much for keeping this network running!</span></p>
                    </div>
                </div>
                <!-- The two choices -->
                <div class="mx-auto flex flex-col lg:flex-row md:flex-row justify-evenly pb-2">
                    <!-- View subscription plans-->
                    <div class="w-full lg:w-1/3 md:w-1/3 p-5">
                        <h3 class="text-center">Subscribe to Charity Quest</h3>
                        <p class="text-center">Very Important Volunteers (V.I.V.s) get exclusive perks and products that are not available on the free tier.</p>
                        <button class="bg-green-600 hover:bg-green-500 text-white font-bold w-full py-4 px-4 rounded border border-green-500 hover:border-transparent" @click="() => handleSubscriptionClick()">View subscription options</button>
                    </div>
                    <div class="w-full lg:w-1/3 md:w-1/3 p-5">
                        <h3 class="text-center">Buy me a coffee!</h3>
                        <p class="text-center">In a hurry? I know that feeling. Leave a one-time tip to keep the caffeine train rolling.</p>
                        <button class="bg-green-600 hover:bg-green-500 text-white font-bold w-full py-4 px-4 rounded border border-green-500 hover:border-transparent" @click="() => handleTipClick()">Tip the team to say thanks</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </Teleport>
</template>

<script>
    import { Teleport } from 'vue'

    export default {
        props: ["questData", "contractData", "close"],
        components: {
            Teleport
        },
        setup(props){
            console.log("Signed up for a quest, good on ya!");
            console.log(props);
            console.log(props.close);
            
            return {
                quest: props.questData,
                contract: props.contractData
            }
        },
        methods: {
            handleSubscriptionClick ( e )
            {
                console.log("Going to the Subscribe Page");
                this.$router.push("/subscribe");
            },
            handleTipClick ( e )
            {
                window.open("https://square.link/u/L3oz4KJx", '_blank', 'noreferrer');
                // Venmo Business may have the best fee rates but it still says "Hoboken Charity Quest"
                // No clue how to change that or delete it and make a new one...
                // Using Square for now.
            }
        },
        computed: {
            questStartTimeDisplay() {
                var startTime = new Date(this.contract.expectedArrivalAt);
                var startTimeText = startTime.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true
                });

                return startTimeText.toLowerCase();
            },
        }
    }
</script>
<template>
  <div class="loginBox">
    <img class="cq-logo" alt="Charity Quest logo" src="../assets/CQ-Logo.png" />
    <h4>
      Please log into your Charity Quest account to view local volunteer
      opportunities.
    </h4>
    <form class="loginForm" @submit.prevent="onSubmit">
      <div class="labelInputRow">
        <label for="loginInputEmail">Email:</label>
        <input
          id="loginInputEmail"
          v-model="loginInputEmail"
          type="email"
          placeholder="Email"
        />
      </div>

      <div class="labelInputRow">
        <label for="loginInputPassword">Password:</label>
        <input
          id="loginInputPassword"
          v-model="loginInputPassword"
          type="password"
          placeholder="Password"
        />
      </div>

      <input class="loginButton" type="submit" value="LOG IN   ►" />

      <!-- Appeal for autologin goes here, togglebox -->
      <!-- It's part of the form because the decision to autologin needs to be known at login time -->
    </form>

    <br />

    <!-- Reset password route -->
    <router-link class="text-white" :to="{ name: 'PasswordReset' }">
      <p class="text-center">
        Click here if you need to reset your password.
      </p>
    </router-link>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";

export default {
  props: ["close"],
  name: "LoginForm",
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      loginInputEmail: "",
      loginInputPassword: "",
      loginRequest: {
        Email: "",
        Password: "",
        TitleId: "FEAAC"
      },
      userId: "shmeh"
    };
  },
  mounted() {
    // console.log("testing cookies")

    if (this.cookies.isKey("username") == true) {
      // console.log(this.cookies.get("username") + " is the Cookie Saved Username");
    } else {
      // console.log("Couldn't find Grongus");
    }
  },
  methods: {
    onSubmit() {
      window.PlayFab.settings.titleId = "FEAAC";
      // console.log("TitleId: " + window.PlayFab.settings.titleId)
      this.loginRequest.Email = this.loginInputEmail;
      this.loginRequest.Password = this.loginInputPassword;
      window.PlayFabClientSDK.LoginWithEmailAddress(
        this.loginRequest,
        this.LoginWithEmailAddressCallback
      );
    },
    async LoginWithEmailAddressCallback(result, error) {
      // console.log("Callback received, sarge!")

      if (result != null) {
        localStorage.setItem('email', this.loginInputEmail)
        localStorage.setItem('pwd', this.loginInputPassword)
        // console.log("Logging in succeeded, result is below:")
        // console.log(result)
        // console.log("I think the Entity ID is " + result.data.EntityToken.Entity.Id)
        // console.log("I think the Entity Type is " + result.data.EntityToken.Entity.Type)
        // console.log("The Player ID of " + this.loginRequest.Email + " is " + result.data.PlayFabId)

        // Set the cookie of the Username
        // console.log("Setting a cookie")
        this.cookies.set("username", "Grongus");

        // Let's get TitleData so we can get a list of Providers and the Maintenance Status (i.e. current Website Version)
        this.$store.dispatch("downloadCharityQuestTitleData"); //maybe include our current version?

        // Set these values in the Store
        this.$store.dispatch("generatePlayerProfile", result.data.PlayFabId);
        this.$store.dispatch(
          "recordPlayerEmailAddress",
          this.loginRequest.Email
        );
        this.$store.dispatch(
          "recordPlayerEntityInformation",
          result.data.EntityToken
        );
        // Hack, but doing this here too
        // DELETE this.$store.state.playerProfile.userAuthorizationToken = result.data.EntityToken.EntityToken;

        // Checking to make sure they have a verified contact email on the account so this doesn't spam them every time
        // Get our Player Profile
        var getPlayerProfileRequest = {
          PlayFabId: result.data.PlayFabId,
          ProfileConstraints: {
            ShowDisplayName: true,
            ShowContactEmailAddresses: true
          }
        };

        // console.log("Checking Player Profile for Email Addresses")
        window.PlayFabClientSDK.GetPlayerProfile(getPlayerProfileRequest)
          .then(response => {
            // console.log("Downloaded Profile Data for " + response.data.PlayerProfile.DisplayName)
            // console.log(response)

            if (
              response.data.PlayerProfile.ContactEmailAddresses != null &&
              response.data.PlayerProfile.ContactEmailAddresses.length > 0
            ) {
              // console.log("OK, we have at least one email.")

              // We have at least one email! Is it verified?
              if (
                response.data.PlayerProfile.ContactEmailAddresses[0]
                  .VerificationStatus == "Confirmed"
              ) {
                // console.log(response.data.PlayerProfile.ContactEmailAddresses[0].EmailAddress + " is already confirmed, do nothing.")
              } else if (
                response.data.PlayerProfile.ContactEmailAddresses[0]
                  .VerificationStatus == "Unverified"
              ) {
                // Force an update
                // console.log("Please verify your email!")
                var addOrUpdateContactEmailRequest = {
                  EmailAddress: this.loginInputEmail
                };
                window.PlayFabClientSDK.AddOrUpdateContactEmail(
                  addOrUpdateContactEmailRequest,
                  this.AddOrUpdateContactEmailCallback
                );
              } else {
                // Email is Pending, do nothing.
                // console.log("Email is still Pending... reminder?")
              }
            } else {
              // console.log("response.data.PlayerProfile.ContactEmailAddresses is probably null. See?")
              // console.log(response.data.PlayerProfile.ContactEmailAddresses)
              // console.log("Told ya!")
            }
          })
          .catch(error => {
            // console.log("There was an error getting our Player Profile.")
            // console.log(error)
          });

        // Is now a good time to trigger some more downloads?

        // GETTING THE PLAYER'S INVENTORY
        this.$store.dispatch("downloadPlayerInventory");

        // GETTING THE PLAYER'S GROUP MEMBERSHIPS
        this.$store.dispatch("downloadPlayerGroupMemberships");

        // We need to make sure we're in the Chariteer Database
        // console.log("Time to search the Database...");
        this.$store.state.playerProfile.chariteerExistsInDatabase = false;
        const chariteerSearch = await this.checkIfChariteerIsInDatabase(
          result.data.PlayFabId
        );
        const creditsSearchResult = await this.downloadCredits(
          result.data.PlayFabId
        );
        this.$store.dispatch("storeChariteerCredits", creditsSearchResult);

        // Route the player to Home, a page that waits patiently for this user's information and adapts itself
        // console.log("Routing home now.");
        this.$emit("login-successful");
        const returnTo = this.cookies.get("returnTo");
        if (returnTo != null) {
          this.cookies.remove("returnTo");
          this.$router.push(returnTo);
        } else {
          this.$router.push("/home");
        }
      } else if (error != null) {
        // console.log("Failure")
        window.PlayFab.GenerateErrorReport(error);
        // send up an alert I guess

        if (error.error == "InvalidEmailOrPassword") {
          alert(
            'This is not the correct password for this Login Email. Please confirm your spelling and try again.\n\nIf you can\'t remember it and you need to reset your password, tap the link that says "Click here if you need to reset your password."'
          );
        } else if (
          error.error == "AccountNotFound" ||
          error.error == "AccountBanned"
        ) {
          alert(
            "There's no account that uses this Login Email. Please check that you've spelled it correctly, and that you're using the email that you created the account with.\n\nIf this is definitely your email and password combination, please email Frank for help at frank@charityquest.io"
          );
        } else if (error.error == "InvalidParams") {
          alert(
            'The email you entered could not be recognized as an email, probably because of a typo. It should look like "email@provider.com", for example.\n\nPlease check your spelling and try again.'
          );
        } else if (error.error == "ServiceUnavailable") {
          alert(
            "Service is unavailable.\n\nCheck your Internet connection and try again."
          );
        }
      }
    },
    CheckIfUserHasVerifiedEmailAddressCallback(result, error) {
      if (result != null) {
        console.log("Here's the situation...");
        console.log(result);
      } else if (error != null) {
        console.log("Couldn't get a Profile.");
      }
    },
    AddOrUpdateContactEmailCallback(result, error) {
      if (result != null) {
        console.log("Contact email verification request sent!");
      } else if (error != null) {
        console.log("Fission Mailed!");
      }
    },
    async checkIfChariteerIsInDatabase(lookingForThisGui) {
      // console.log("Is this user in our new Chariteer database? " + lookingForThisGui + " : " + this.$store.state.playerProfile.userAuthorizationToken);

      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
          query GetChariteerById {
            chariteer (id: "${lookingForThisGui}") {
              id
              username
              nameFirst
              nameMiddle
              nameLast
              playerAccountType
              subscriptionStatus
              contracts {
                id
                expectedArrivalAt
                expectedDepartureAt
                actualArrivalAt
                actualDepartureAt
                status
              }
              emailAddress
              dateOfBirth
              favoriteDistricts {
                id
              }
            }
          } 
          `
        })
      });

      // console.log("Chariteer Search Result:");
      // console.log(result);

      // Data will be the actual Chariteer block returned by the Query.
      // Maybe this is how we can set our State?
      const data = await result.json();
      // console.log(data);

      if (data.errors && data.errors.length > 0) {
        // console.log(data.errors[0].extensions.code);

        if (data.errors[0].extensions.code == 404) {
          // Add the Chariteer, potentially do not proceed to do stuff
          // console.log("We're going to have to add this returning Chariteer to the new system.");

          // Set a flag
          this.$store.state.playerProfile.chariteerExistsInDatabase = false;
        }
      } else {
        // Do nothing
        // console.log("User is already in the database, we're OK.");
        this.$store.state.playerProfile.chariteerExistsInDatabase = true;
      }

      return data;
    },
    async downloadCredits(chariteerID) {
      // console.log("Getting the Credits for " + chariteerID);

      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
          query GetMyCredits {
            credits (chariteerID: "${chariteerID}") {
              id
              playerId
              playerAccountType
              quest {
                id
                provider {
                  id
                  name
                }
                eventTitle
                eventType
                publishingStatus
                serviceTags
                startedAt
              }
              questId
              type
              unit
              value
            }
          } 
          `
        })
      });

      // console.log("Credits Result:");
      // console.log(result);
      const data = await result.json();
      // console.log("Credits Data:");
      //console.log(data);

      if (data.errors && data.errors.length > 0) {
        console.log("CREDITS ERROR:");
        console.log(data.errors[0].extensions.code);

        if (data.errors[0].extensions.code == 404) {
          console.log("404 :(");
        }
      } else {
        // Do nothing. Getting an empty array is OK.
        console.log("No errors for Credit.");
      }

      return data;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loginBox {
  background-color: #ff000000;
  width: 600px;
  padding-top: 5vh;
  margin: 0 auto; /* Set the app to be centered */
  margin-bottom: 5vh;
}

h4 {
  color: #ffffff;
  font-weight: 500;
  margin-top: 20px;
  margin-right: 100px;
  margin-left: 100px;
  margin-bottom: 20px;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  /* height: 256px; */
  object-fit: cover;
  background: #ff000000;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  text-align: left;
  width: 300px;
  border-radius: 4px;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
  margin: 0 auto; /* Set the app to be centered */
}

label {
  float: left;
  text-align: right;
  margin-right: 10px;

  padding-bottom: 10px;
  outline-color: #ffa94e;
  width: 100px;
}

.loginButton {
  background-color: #ffa94e;
  border: none;
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px 50px;
  outline: none;

  color: #ffffff;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
  /* box-shadow: 0 1px 1px 1px rgba(0,0,0,0.1); */
}

.loginButton:hover {
  background-color: #fdbd78;
}

.loginButton:active {
  background-color: #ffa13c;
  transform: translateY(1px);
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {
  .loginBox {
    background-color: rgba(255, 10, 235, 0);
    max-width: 100%;
  }
  h4 {
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .loginForm {
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
}
</style>

<template>
  <div class="quests">
    <TopNav/>

    <!-- This can have a Provider View and a Chariteer View, I suppose. -->
    <!-- PROVIDER VERSION shows first your evergreen quests (no end date) and then a schedule of your temporal quests -->
        <!-- Different interior tabs for current, drafts, pending, cancelled, and completed in that order -->
    <!-- CHARITEER VERSION shows any outstanding applications you've submitted and then a schedule of your temporal quests -->
        <!-- Different tabs for Active and Completed, maybe pending just for sanity's sake -->

    <template v-if="$store.state.playerProfile.accountType == 'Provider'">
      <ProviderPastQuests/>
      <!-- Potential "Show More" Button goes here if you want to cut it off at the first 5 entries or something -->
    </template>

    <Footer/>
  </div>
</template>

<script>
import TopNav from "@/components/TopNav.vue";
import ProviderPastQuests from "@/components/ProviderPastQuests.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Quests",
  components: {
    TopNav,
    ProviderPastQuests,
    Footer
  }
}
</script>

<style scoped>

.quests {
  background-color: #FFA94E;
}

</style>

<template>
  <div>
    <img
      :src="starIcon"
      @click="handleClick"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :class="{ 'unfilled-hover-effect': !isLiked }"
      class="star-icon w-8"
    />
  </div>
</template>

<script>
export default {
  props: {
    isLiked: {
      type: Boolean,
      required: true
    },
    onClick: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      hover: false,
      filledStarIcon: "../../assets/star-filled-icon.png",
      unfilledStarIcon: "../../assets/star-unfilled-icon.png"
    };
  },
  methods: {
    handleClick() {
      this.$emit("click");
    }
  },
  computed: {
    starIcon() {
      return this.isLiked || this.hover
        ? require("@/assets/star-filled-icon.png")
        : require("@/assets/star-unfilled-icon.png");
    }
  }
};
</script>

<style>
.star-icon {
  transition: filter 0.15s;
}
.star-icon:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

.unfilled-hover-effect {
  transition: filter 0.15s;
}
.unfilled-hover-effect:hover {
  filter: brightness(0.8);
}
</style>

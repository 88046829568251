<template>
    <Teleport to="#modalScreen">
        <div class="fixed top-0 left-0 w-screen h-screen z-80">
            <!-- Black Background -->
            <div
                name="Blackground"
                class="bg-black opacity-75 w-screen h-screen absolute top-0 left-0"
                @click="() => close()"
            />
            <div
                class="bg-cq-orange w-2/3 z-90 mx-auto top-20 relative py-5 px-5 rounded-md"
            >
                <!-- <div class="bg-cq-orange rounded-lg shadow-xl" @click.stop> -->
                <LoginForm @login-successful="() => close()" />
            </div>
        </div>
    </Teleport>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";
import { Teleport } from "vue";
export default {
    props: ["close"],
    components: {
        LoginForm,
    },
};
</script>

<style scoped>
.are-you-sure-you-want-to-volunteer-modal {
    /* display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
}
</style>

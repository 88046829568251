<script>
    import { Teleport } from 'vue'
    import { ref } from 'vue'
    // Range Slider
    import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/default.css'
    import { formatAMPM } from '@/helpers/index.js'

    export default {
        props: ['questData', 'handleSignUp', 'close'],
        components: {
            Teleport,
            VueSlider
        },
        setup(props){
            // console.log("Editing Flex Times...");
            // console.log(props);
            // console.log(props.close);

            var startDate = new Date(props.questData.startedAt);
            var endDate = new Date(props.questData.endedAt);
            const minRange = (startDate.getHours() * 60) + (startDate.getMinutes());
            const maxRange = (endDate.getHours() * 60) + (endDate.getMinutes());
            const timeSelectedValues = ref([minRange, maxRange]);
            
            return {
                quest: props.questData,
                minRange,
                maxRange,
                timeSelectedValues
            }
        },
        methods: {
            formatTime( minutes ) {
                // console.log(minutes);

                const remainderMinutes = minutes%60;
                const hours = (minutes - remainderMinutes)/60;

                // var time = new Date(minRange);
                // var newDateObj = new Date(time.getTime() + minutes*60000);

                return formatAMPM( hours, remainderMinutes );
            },
            formatTimeForDatabase( minutes ) {
                let difference = minutes - this.minRange;

                // console.log("Min Range is " + this.minRange + ", minutes is " + minutes);
                // console.log("Our designated time is " + this.quest.startedAt + " plus " + difference + " minutes.");


                const startTime = new Date(this.quest.startedAt);

                return new Date(startTime.getTime() + difference*60000);;
            }
        },
        computed: {
            formattedStartTime ( ) {
                if ( this.quest && this.quest.startedAt )
                {
                    var startTime = new Date(this.quest.startedAt);

                    return startTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                }
                else
                {
                    return "Unknown Start Time"
                }
                
            },
            formattedEndTime ( ) {
                if ( this.quest && this.quest.endedAt )
                {
                    var endTime = new Date(this.quest.endedAt);

                    return endTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                }
                else
                {
                    return "Unknown End Time"
                }
            },
            formattedTotalMinutes ( ) {
                var totalMinutes = this.timeSelectedValues[1] - this.timeSelectedValues[0];
                return totalMinutes;
            }
        }
    }
</script>

<template>
    <Teleport to="#modalScreen">
    <div class="fixed top-0 left-0 w-screen h-screen z-80">
        <!-- Black Background -->
        <div name="Blackground" class="bg-black opacity-75 w-screen h-screen absolute top-0 left-0" @click="() => close()" />
        
        <!-- The Pop Up -->
        <div class="bg-white w-2/3 z-90 mx-auto top-20 relative py-5 px-5">
            <h1 class="text-center">How long can you volunteer?</h1>
            <h3 class="text-center">This Quest is flexible, which means you can tell <span class="underline">{{ quest.provider.name }}</span> when you plan to arrive at <span class="underline">{{ quest.location.name }}</span> and when you need to leave.</h3>
            <p class="text-center">Choose your times:</p>
            
            <p>{{ hours }}</p>

            <!-- The Static Time Labels -->
            <div class="flex flex-row justify-between">
                <p>{{ formattedStartTime }}</p>
                <p>{{ formattedEndTime }}</p>
            </div>

            <!-- Range Slider -->
            <div class="mx-5">
                <VueSlider 
                    v-model="timeSelectedValues"
                    :interval="15"
                    :min="minRange"
                    :max="maxRange"
                    :min-range="quest.flexibleMinimumTime"
                    :tooltip-formatter="val => formatTime(val)"
                    :enable-cross="false"
                >
                </VueSlider>
            </div>

            <!-- The Chosen Time Labels -->
            <div class="flex flex-row justify-between mx-10">
                <p>{{ formatTime(this.timeSelectedValues[0]) }}</p>
                <p>{{ formatTime(this.timeSelectedValues[1]) }}</p>
            </div>
            
            
            <p class="text-center">{{ "Volunteering for " + formattedTotalMinutes + " minutes."}}</p>
            <p class="text-center">{{ "Required Minimum Time: " + quest.flexibleMinimumTime + " minutes"}}</p>
        
            <!-- The buttons -->
            <div class="mx-auto grid grid-cols-2 space-x-10">
                <button class="bg-red-500 hover:bg-red-400 text-white font-bold py-4 px-4 rounded border border-red-100 hover:border-transparent" @click="() => close()">I'm not volunteering...</button>
                <button class="bg-green-500 hover:bg-green-400 text-white font-bold py-4 px-4 rounded border border-green-100 hover:border-transparent" @click="() => handleSignUp(formatTimeForDatabase(timeSelectedValues[0]), formatTimeForDatabase(timeSelectedValues[1]))">Ready, sign me up!</button>
            </div>
            
        </div>
    </div>

    </Teleport>
</template>
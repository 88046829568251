<template>
  <!-- This is a special version of Quest Card for Providers that shows more detailed information and has different interactions. -->
  
  <!-- Date Header -->
  <!-- Maybe I can scan this list and check this date heading against an array of indexes of unique dates -->
  <!-- <p>{{ questDayName + " " + questMonthNumber + "/" + questDayNumber }} is {{ quest.eventID }}</p> -->

  <!-- Quest Card -->
  <button class="questCard" v-on:click="onClick" v-bind:class="{ atMaxCapacity: isFull }" v-show="quest.eventType == 'special' || quest.eventType == 'supplyRequest'">
    <div class="questCard-flexbox">
      <div class="questCard-leftSide">
        <h2>{{ quest.eventTitle }}</h2>
        <p>{{ "Location: " + quest.locationName }}</p>

        <!-- Regular Quest -->
        <p v-show="quest.eventType == 'special'">{{ questDayName + " " + questMonthNumber + "/" + questDayNumber }} <small>{{ quest.startTime + " to " + quest.endTime  }}</small></p>

        <!-- Supply Request -->
        <p v-show="quest.eventType == 'supplyRequest'" v-if="quest.endTime != undefined">{{ questDayName + " " + questMonthNumber + "/" + questDayNumber }} <small>{{ quest.startTime + " to " + quest.endTime  }}</small></p>
        <p v-show="quest.eventType == 'supplyRequest'" v-else>{{ questDayName + " " + questMonthNumber + "/" + questDayNumber }} <small>{{ "Delivery time: " + quest.startTime }}</small></p>
      </div>

      <!-- Volunteer Display -->
      <div class="questCard-rightSide">

        <!-- If Quest is full: -->
        <div class="questCard-full" v-if="isFull == true">
          <!-- Supply Requests -->
          <template v-if="quest.eventType == 'supplyRequest'">
            <h1 class="questCard-volunteer-bubble-full-supplyRequest">{{ numberOfVolunteersOnQuest }} ✔</h1>
            <h3 v-if="numberOfVolunteersOnQuest == 1">Supplier</h3>
            <h3 v-else>Suppliers</h3>
          </template>
          <!-- Quest (Special) -->
          <template v-else>
            <h1 class="questCard-volunteer-bubble-full">{{ numberOfVolunteersOnQuest }} ✔</h1>
            <h3 v-if="numberOfVolunteersOnQuest == 1">Volunteer</h3>
            <h3 v-else>Volunteers</h3>
          </template>
        </div>

        <!-- If Quest is empty: -->
        <div class="questCard-empty" v-else-if="numberOfVolunteersOnQuest == 0">
          <!-- Supply Requests -->
          <template v-if="quest.eventType == 'supplyRequest'">
            <h1 class="questCard-volunteer-bubble-empty-supplyRequest">{{ numberOfVolunteersOnQuest }}<small> of </small>{{ quest.volunteerMax }}</h1>
            <h3>Suppliers</h3>
          </template>
          <!-- Quest (Special) -->
          <template v-else>
            <h1 class="questCard-volunteer-bubble-empty">{{ numberOfVolunteersOnQuest }}<small> of </small>{{ quest.volunteerMax }}</h1>
            <h3>Volunteers</h3>
          </template>
        </div>

        <!-- Else -->
        <div class="questCard-openSpots" v-else>
          <!-- Supply Requests -->
          <template v-if="quest.eventType == 'supplyRequest'">
            <h1 class="questCard-volunteer-bubble-openSpots-supplyRequest">{{ numberOfVolunteersOnQuest }}<small> of </small>{{ quest.volunteerMax }}</h1>
            <h3>Suppliers</h3>
          </template>
          <!-- Quest (Special) -->
          <template v-else>
            <h1 class="questCard-volunteer-bubble-openSpots">{{ numberOfVolunteersOnQuest }}<small> of </small>{{ quest.volunteerMax }}</h1>
            <h3>Volunteers</h3>
          </template>
        </div>
      </div>
    </div>

    <!-- Small centered titlecard that indicates a Guild has claimed this Quest Card -->
    <div class="questCard-guildCenterPlate" v-if="isRaided == true && isFull == false">Group: {{ quest.raidInfo.raidingGroupName }}</div>
    <div class="questCard-guildCenterPlate-full" v-if="isRaided == true && isFull == true">Group: {{ quest.raidInfo.raidingGroupName }}</div>

  </button>
</template>

<script>
export default {
  props: {
    quest: Object
  },
  methods: {
    onClick() {
      console.log("CLICK")

      var tagsObj = this.quest
      tagsObj.messageType = "Chariteer Views Quest"
      // this.$store.dispatch('clickTrackLogForAmazon', tagsObj)
      
      this.$router.push({ name: 'ProviderQuestDetails', params: {id: this.quest.eventID}})
    }
  },
  computed: {
    questDayName() {
      var dayIndex = new Date(this.quest.date).getDay()

      switch (dayIndex) {
        case 0:
          return "Sunday"
        case 1:
          return "Monday"
        case 2:
          return "Tuesday"
        case 3:
          return "Wednesday"
        case 4:
          return "Thursday"
        case 5:
          return "Friday"
        case 6:
          return "Saturday"
      }

      return "Day"
    },
    questMonthNumber() {
      return (new Date(this.quest.date).getMonth() + 1)
    },
    questDayNumber() {
      return new Date(this.quest.date).getDate()
    },
    questSlotsRemaining() {
      return ( this.quest.volunteerMax - this.quest.volunteerInfo.length )
    },
    numberOfVolunteersOnQuest() {
      if ( this.quest.volunteerInfo != undefined )
        return ( this.quest.volunteerInfo.length )
      else
        return 0
    },
    isFull() {
      if ( this.quest.volunteerInfo != undefined && this.quest.volunteerInfo.length >= this.quest.volunteerMax )
      {
        return true
      }
      else
      {
        return false
      }
    },
    isRaided () {
      if ( this.quest.raidInfo != null && this.quest.raidInfo.raidingGroupID != "" )
      {
        return true
      }
      else
      {
        return false
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* # of Slots Remaining */
h1 {
  color: #444444;
  text-align: center;
  font-size: 250%;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Quest Title */
h2 {
  margin: 0px;
  margin-left: 0px;
}

h3 {
  color: #444444;
  text-align: center;
  font-size: 75%;
  margin-top: 5px;
  margin-bottom: 0px;
}

p {
  color: #444444;
  text-align: left;
  margin-top: 10px;
  margin-left: 0px;
  margin-bottom: 0px;
}

small {
  margin-left: 20px;
}

.questCard {
  display: flex;
  flex-direction: column;
  color: #FFA94E;
  font-family: Palanquin, Montserrat, Helvetica, Arial, sans-serif;
  font-size: 110%;
  text-align: left;
  width: 500px;
  cursor: pointer;
  background-color: white;
  border: 3px solid rgba(122, 122, 122, 0.0);
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);

  white-space: nowrap;
}

.questCard-flexbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/*
.questCard-leftSide {
}*/

.questCard-rightSide {
  padding-right: 10px;
}

/* Quests that are EMPTY */

.questCard-empty {
  width: 100px;
}

.questCard-empty small {
  margin: 0px;
  font-size: 50%;
  font-weight: 400;
}

.questCard-volunteer-bubble-empty {
  background-color: rgb(255, 109, 109);
  border: 5px solid rgb(255, 109, 109);
  border-radius: 50px;
}

.questCard-volunteer-bubble-empty-supplyRequest {
  background-color: rgb(255, 109, 109);
  border: 5px solid rgb(255, 109, 109);
  border-radius: 10px;
}

/* Quests with OPEN SPOTS */

.questCard-openSpots {
  width: 100px;
}

.questCard-openSpots small {
  margin: 0px;
  font-size: 50%;
  font-weight: 400;
}

.questCard-volunteer-bubble-openSpots {
  background-color: rgb(255, 195, 85);
  border: 5px solid rgb(255, 195, 85);
  border-radius: 50px;
}

.questCard-volunteer-bubble-openSpots-supplyRequest {
  background-color: rgb(255, 195, 85);
  border: 5px solid rgb(255, 195, 85);
  border-radius: 5px;
}

.questCard-guildCenterPlate {
  background-color: rgb(100, 100, 100);
  color: white;
  font-weight: 600;
  margin: auto;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

.questCard-guildCenterPlate-full {
  background-color: rgb(96, 146, 96);
  color: white;
  font-weight: 600;
  margin: auto;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}



/* Quests at MAX CAPACITY */

.atMaxCapacity {
  color: black;
  background-color: rgb(189, 255, 189);
  border: 3px solid rgba(122, 122, 122, 0.2);
}

.atMaxCapacity h2 {
  color: rgb(83, 172, 83);
}

.atMaxCapacity p {
  color: rgb(96, 187, 96);
  font-weight: 500;
}

.questCard-volunteer-bubble-full {
  background-color: rgb(99, 243, 123);
  border: 5px solid rgb(99, 243, 123);
  border-radius: 50px;
  padding-right: 10px;
  padding-left: 10px;
}

.questCard-volunteer-bubble-full-supplyRequest {
  background-color: rgb(99, 243, 123);
  border: 5px solid rgb(99, 243, 123);
  border-radius: 5px;
  padding-right: 10px;
  padding-left: 10px;
}


/* Interactions */

.questCard:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.5);
}

.event-link {
  color: #2c3e50;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .questCard {
    width: 100%;
    flex-wrap: wrap;
    padding: 1vw;
  }
  .questCard-leftSide {
    width: 60vw;
  }
  .questCard-rightSide {
    width: 20vw;
  }
  h2 {
    margin-left: 5px;
  }
  p {
    font-size: 75%;
    margin-left: 5px;
  }
}
</style>

<template>
  <div>
    <!-- Email Submission Form -->
    <div v-if="$store.state.playerProfile.accountType == 'Chariteer'" class="w-1/2 mx-auto py-3 bg-white rounded">
      <!-- Header -->
      <p class="text-3xl text-center font-bold m-0">Account Removal</p>
      <p class="mx-10">Sorry to see you go! To delete your account from Charity Quest completely, please type the words <span><b>delete forever</b></span> into this box and press submit. Warning: this will flush all of your volunteer data, and I will not be able to process volunteer service records for court, school, or work if you proceed.</p>

      <!-- Input Box -->
      <div class="flex items-center justify-center h-2/3">
          <input
              v-model="doomedEmail"
              class="text-xl text-center w-2/3 py-1"
              type="text"
              placeholder="q_q"
              required
          > 
      </div>
      <button class="bg-red-500 hover:bg-red-400 text-black font-medium w-1/2 py-1 px-4 rounded border border-red-100 hover:border-transparent" v-on:click="deleteAccountForever">REMOVE THIS ACCOUNT   ►</button>
    
    </div>
    <div v-else>
      <p>Please log in to remove your account from Charity Quest.</p>
    </div>
    
  </div>
</template>

<script>
import { useQuery } from '@vue/apollo-composable'
import { useRoute } from 'vue-router';
import gql from 'graphql-tag'
import { toast } from 'vue3-toastify';

export default {
  name: "RemoveAccountForm",
  data() {
    return {
      doomedEmail: ''
    }
  },
  methods: {
    async deleteAccountForever () {
      console.log(this.doomedEmail);

      if ( this.doomedEmail == "delete forever")
      {
        const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
            mutation RemoveChariteer ($RCI: RemoveChariteerInput!) {
              removeChariteer (removeChariteerInput: $RCI) {
                id
              }
            } 
            `,
            variables: {
              "RCI": {
                "emailAddress": this.$store.state.playerProfile.emailAddress,
              }
            }
          })
        })

        
        const data = await result.json();
        console.log(data);

        if ( data.errors && data.errors.length > 0 )
        {
          // console.log(data.errors[0].extensions.code);

          if ( data.errors[0].extensions.code == 404 )
          {
            

          }
        }
        else
        {
          this.notifySuccess("Account deleted.");
          this.$store.state.playerProfile = {};
          this.$router.push('/');
        }
      }
      else
      {
        this.notifyError("You have to type delete forever in the box!");
      }
    },
    validateEmailInput () {
      // Don't allow spaces!
    }
  },
  setup() {
    console.log("We're in setup.");
    const notifyError = ( text ) => {
      toast.error(text, {
        autoClose: false,
      }); // ToastOptions
    }

    const notifySuccess = ( text ) => {
      toast.success(text, {
        autoClose: 10000,
      }); // ToastOptions
    }

    return {
      notifyError,
      notifySuccess
    }
  },
};
</script>
<template>
  <div class="bg-[#ffa94e] min-h-screen mt-0">
    <TopNav />
    <FDFullWidthBanner />
    <div v-if="loading" class="flex flex-col items-center justify-center mt-12">
      <Skeleton width="20%" height="3rem" borderRadius="5px" class="mb-2" />
      <Skeleton width="10%" height="1rem" borderRadius="5px" class="mb-4" />
      <Skeleton width="95%" height="20rem" borderRadius="5px" />
    </div>
    <div v-if="district">
      <DistrictDetails :district="district" />
    </div>
  </div>
  <Footer />
</template>

<script>
import TopNav from "../components/TopNav.vue";
import FDFullWidthBanner from "../components/advertisements/FDFullWidthBanner.vue";
import Footer from "../components/Footer.vue";
import { defineComponent } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import DistrictDetails from "@/components/DistrictDetails.vue";
import gql from "graphql-tag";
import { computed } from "vue";
import Skeleton from "primevue/skeleton";

export default defineComponent({
  components: {
    DistrictDetails,
    TopNav,
    FDFullWidthBanner,
    Footer,
    Skeleton
  },
  setup() {
    const route = useRoute();
    const districtId = route.params.id;

    const { result, loading, error } = useQuery(
      gql`
        {
          district(id: ${districtId}) {
            id
            name
            acronym
            county
            state
            noun
            centerLatitude
            centerLongitude
            zoomLevel
            declaredChariteers {
              id
              username
            }
          }
        }
      `
    );
    console.log(result);
    const district = computed(() => result.value?.district);

    console.log(district);
    return {
      district,
      loading,
      error
    };
  }
});
</script>

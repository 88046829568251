<template>
  <div class="groups">
    <TopNav/>

    <!-- The Groups page is for everyone. It shows you what Groups you belong to and lets you take actions based on your Role(s) and permissions. -->

    <!-- ZERO GROUPS -->
    <!-- If you belong to 0 Groups, instead of showing these modules we'll just display a sad message. But in the future, browsing Groups would be cool. -->
    <template v-if="$store.state.groupMemberships.length == 0">
      <h1>You do not belong to any Groups.</h1>
    </template>

    <!-- If you belong to 1 Group, we'll show you the important modules-->
    <template v-if="$store.state.groupMemberships.length >= 1">
      <h1>{{ $store.state.currentlyViewedGroup.GroupName }}</h1>

      <div class="groupTabs">
        <button class="groupTabButton" v-bind:class="{ groupTabButtonUnselected: currentWindow != 'members'}" v-on:click="selectTab('members')">Members</button>
        <button class="groupTabButton" v-bind:class="{ groupTabButtonUnselected: currentWindow != 'applications'}" v-on:click="selectTab('applications')">Applications</button>
        <button class="groupTabButton groupTabButtonInactive">Invitations</button>
        <button class="groupTabButton groupTabButtonInactive">Blocked</button>
      </div>

      <!-- Group Members -->
      <template v-if="currentWindow == 'members'">
        <GroupMembers/>
      </template>

      <template v-if="currentWindow == 'applications'">
        <GroupApplications/>
      </template>
    </template>


    <!-- TODO: If you belong to 2 or more Groups, you'll see a "Group Select" window where the groups are listed and you pick one and then see that Group's controls. -->

  </div>
</template>

<script>
import TopNav from "@/components/TopNav.vue";
import GroupMembers from "@/components/GroupMembers.vue";
import GroupApplications from "@/components/GroupApplications.vue"

// Some kind of selector value here so we know which window to show

export default {
  name: "Groups",
  components: {
    TopNav,
    GroupMembers,
    GroupApplications
  },
  data() {
    return {
      currentWindow: 'members'
    }
  },
  methods: {
    selectTab (tab)
    {
      console.log("Next tab up: " + tab)

      this.currentWindow = tab
    },
  },
  created() {
    console.log("Setting our currently viewed Group.")

    // This should really be done with a dispatch / action in the future
    if ( this.$store.state.groupMemberships.length > 0 )
    {
      this.$store.state.currentlyViewedGroup = this.$store.state.groupMemberships[0]
      console.log("Currently viewed Group is " + this.$store.state.currentlyViewedGroup.GroupName)
    }
    else
    {
      console.log("This user does not have any Group Memberships.")
    }
  },
}
</script>

<style scoped>

.groups {
  background-color: #FFA94E;
  padding-bottom: 100px;
}

.groupTabButton {
  background-color: #ffffff;
  border: none;
  border-radius: 4px 4px 0px 0px;
  margin-top: 0px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 0px;
  padding: 10px 20px;
  outline: none;

  color: rgb(50, 50, 50);
  font-family: Palanquin, Montserrat, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 110%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
}

.groupTabButtonUnselected {
  background-color: #ffd4a6;
  cursor: pointer;
}

.groupTabButtonUnselected:hover {
  background-color: #fff4e9;
}

.groupTabButtonInactive {
  background-color: rgb(160, 160, 160);

  color: rgb(130, 130, 130);
}

</style>

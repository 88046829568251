<template>
  <div class="login">
    <TopNav v-show="true"/>
    <FDFullWidthBanner/>
    <LoginForm/>
    <Footer v-show="true"/>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from "@/components/LoginForm.vue";
import TopNav from "@/components/TopNav.vue";
import FDFullWidthBanner from "../components/advertisements/FDFullWidthBanner.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Login",
  components: {
    LoginForm,
    TopNav,
    FDFullWidthBanner,
    Footer
  }
};
</script>

<style scoped>
.login {
  background-color: #FFA94E;
  margin: 0px;
}
</style>

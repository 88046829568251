<template>
  <div class="email-verified">
    <TopNav/>
    <div class="h-[80vh] text-white">
      <h1>Your email has been verified!</h1>
      <p>You can close this window now.</p>
    </div>
    <Footer/>
  </div>
</template>


<script>
// Terms of Service is a static screen that just displays text.

import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "PrivacyPolicy",
  components: {
    TopNav,
    Footer
  }
}
</script>

<style scoped>

.email-verified {
  background-color: #FFA94E;
  position: relative;
  min-height: 100vh;
}

</style>
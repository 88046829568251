<template>
  <div class="flex flex-col" :class="backgroundColor">
    <TopNav />
    <div class="min-h-[80vh]">
      <slot />
    </div>
    <div class="mt-auto">
      <Footer />
    </div>
  </div>
</template>

<script>
import TopNav from './TopNav.vue'
import Footer from './Footer.vue'

export default {
  name: 'MasterLayout',
  components: {
    TopNav,
    Footer
  },
  props: [
    'backgroundColor'
  ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
  <div class="feature-coming-soon bg-[#FFA94E] min-h-screen flex flex-col">
    <TopNav />
    <div
      class="flex-grow container mx-auto flex flex-col items-center justify-center p-4 text-center"
    >
      <div
        class="message-box bg-white rounded-lg shadow-lg p-6 lg:p-12 max-w-lg"
      >
        <h2 class="text-2xl font-semibold mb-0">
          Under Construction!
        </h2>
        <img
          src="@/assets/construction.svg"
          alt="Under Construction"
          class="w-[7rem] h-[7rem] mb-0"
        />
        <p class="mb-4">
          We're on the brink of introducing a new feature, crafted with your
          needs and desires at the forefront.
        </p>
        <p class="mb-4">
          Stay tuned for the unveiling. We're just as excited as you are!
        </p>
        <p class="text-sm italic">
          Noticed an interruption? You might need to
          <strong>
            <router-link to="/login">login </router-link>
          </strong>
          or
          <strong> <router-link to="/">go back home </router-link> </strong>to
          proceed with your activities. We're smoothing out the edges to ensure
          a seamless experience. Thanks for bearing with us!
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "FeatureComingSoon",
  components: {
    TopNav,
    Footer
  }
};
</script>

<style>
/* Ensure you have Tailwind CSS configured in your project */
</style>

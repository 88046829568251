<template>
  <div class="bg-cq-orange">
    <TopNav v-show="true"/>
    <RemoveAccountForm/>
    <Footer v-show="true"/>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";
import RemoveAccountForm from "@/components/RemoveAccountForm.vue";

export default {
  name: "AccountRemoval",
  components: {
    RemoveAccountForm,
    TopNav,
    Footer
  }
};
</script>

<template>
  <!-- Center Column code for Background-->
  <div class="max-w-[100%] mx-auto mb-8">
    <!-- Styles here apply to the entire Component -->
    <div class="">
      <!-- Main Logo / Hero Image -->
      <div class="pb-5 relative">
        <img class="absolute inset-0 w-full h-full object-full opacity-50" alt="Background" src="../assets/contests/CQ-Background_Bricks.png" />
        <img class="relative z-10 mx-auto w-1/2 flex items-center justify-center" alt="Clash of Clubs Logo" src="../assets/contests/logo_clash_of_clubs_transparent.png" />
      </div>
    </div>
    <!-- Center Column code-->
    <div class="max-w-[80%] mx-auto">
        <!-- Sponsors -->
        <div class="pt-5 pb-5 flex justify-evenly">
          <a href="https://www.provident.bank" target="_blank" rel="noopener noreferrer">
            <img class="w-128 mr-4" alt="Provident Bank." src="../assets/contests/logo_Provident_Bank.png" />
          </a>
          <a href="https://6parkproductions.com" target="_blank" rel="noopener noreferrer">
            <img class="w-128 ml-4" alt="Park Productions." src="../assets/contests/logo_6_Park_Productions.png" />
          </a>
        </div>



        <!-- Overview -->
        <div class="bg-white text-orange font-bold p-6 mb-4 rounded-lg shadow-md">
          <!--The contest overview is a short paragraph about the rules, along with some tags underneath it. I might make these clickable in the future.-->
          <p class="text-lg">Charity Quest is excited to host our first group volunteer competition ever!</p>
          <p class="text-base">From March 1st to March 31st, all volunteer hours earned through our app will be added to your organization's total. The winner will be crowned on March 31st and they will decide a local Hudson County charity in our network to receive the prize pot! We are extrememly thankful for our sponsors for their donations to the prize for this event!</p>
        </div>

        <!--The tags should be in a row under the overview, next to each other -->
        <div class="font-bold flex flex-row justify-evenly">
          <div class="rounded-lg bg-white px-4 py-2">
          <h4 class="m-0">Small Groups</h4>
          </div>
          <div class="rounded-lg bg-white px-4 py-2">
            <h4 class="m-0">Winner's Choice Donation</h4>
          </div>
          <div class="rounded-lg bg-white px-4 py-2">
            <h4 class="m-0">Hudson County, NJ</h4>
          </div>
        </div>




  
    
        <!-- Current Prize Pot, should be kind of flashy -->
        <div class="font-bold bg-gradient-to-br from-yellow-400 via-yellow-300 to-yellow-200 rounded-lg p-6 shadow-md mt-4 mb-4 text-center animate-pulse">
         <div class="text-3xl text-yellow-800">CURRENT PRIZE POT</div>
         <p class="m-0 mb-5 text-6xl"><span class="text-green-500 font-serif">$</span> 1000</p>
        </div>

        <!-- Space between the Prize Pot and the Charity List-->
        <div class="bg-orange pb-24"></div>
    
        <!-- Each charity listed below in the order they joined the network -->
        <!--Each charity needs its own bubble that says the Name of the charity, a short description, and then an embedded YouTube video. Use a placeholder for now.-->
        <!-- I'll write one of these so you have something to go on -->
        <div class="flex justify-center font-bold text-white text-3xl">Hoboken, NJ</div>
        <div class="flex flex-wrap justify-center justify-evenly">
          <!--Hoboken Community Center Pantry-->
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
            <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Hoboken Community Center Pantry</summary>
            <div class="p-4">
              <p class="mb-2">The Hoboken Community Center Pantry is a community resource for supplemental food, hygiene and pet supplies for Hoboken, NJ, as well as surrounding communities in Hudson County. The Pantry is a part of the Hoboken Community Center, a 501(c)(3) nonprofit organization that provides essential services and spaces to support the well-being of the Hoboken, NJ community. The Pantry serves the residents of the Hoboken Community Center Affordable Housing and community members experiencing food insecurity or other hardships.<br><br>The Pantry is a member agency of the Community Food Bank of NJ. The HCC Pantry has a pre-packed shelf stable grocery distribution twice monthly and a personal care pantry (includes basic hygiene items, baby items including formula, diapers/pullups, menstrual and incontinence products, & pet basics) once monthly. We are located in the Hoboken Community Center at 1301 Washington Street.</p>
              <a href="https://hobokencc.org" target="_blank">https://hobokencc.org</a>
              <!--
              <p class="mb-2">Here's the Executive Director Toni Tomarazzo with a brief message on why the winner should choose them as the recipient</p>
              <div class="aspect-w-16 aspect-h-9">
                <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
              </div>
              -->
            </div>
            </details>
          </div>

          <!--Hoboken Shelter-->
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
            <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Hoboken Shelter</summary>
              <div class="p-4">
                <p class="mb-2">Opened in 1982, The Hoboken Shelter is a community partnership that transforms lives by providing meals for the hungry, shelter for the homeless, & services to support people to become housed.  These services help our homeless guests develop the skills needed to gain employment, achieve independence, and re-integrate into the community.<br><br>The Hoboken Shelter provides food, shelter, case management, counseling, skills-training workshops, and permanent supportive housing solutions to our guests.</p>
                <a href="https://www.hobokenshelter.org" target="_blank">https://www.hobokenshelter.org</a>
                <!--
                <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div> -->
              </div>
            </details>
          </div>

          <!-- Computers 4 People -->
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Computers 4 People</summary>
              <div class="p-4">
                <p class="mb-2">Computers For People Inc. was founded in 2019 by then 15-year-old Dylan Zajac in Hoboken, NJ. The non-profit was founded with a dual purpose: addressing the alarming issue of e-waste pollution and bridging the expanding gap in digital accessibility. Dylan's forward-looking approach aimed at creating a sustainable solution to the environmental woes posed by discarded electronics, while also tackling the pressing challenge of the digital divide.<br><br>Over the years, Computers For People has blossomed into a leading force in its domain, making significant strides in narrowing the digital divide across regions like New Jersey, New York City, and Massachusetts. Through its tireless efforts, this organization has not only transformed discarded technology into valuable resources but has also transformed lives by providing equal access to the digital realm for all.</p>
                <a href="https://www.computers4people.org" target="_blank">https://www.computers4people.org</a>
                <!--
                <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div> -->
              </div>
            </details>
          </div>

          <!-- Street Life Ministry -->
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Street Life Ministry</summary>
              <div class="p-4">
                <p class="mb-2">Street Life Ministry Inc. is a Mobile Faith-Based Non-Profit 501 (c)(3) Homeless Outreach Ministry based out of Hoboken NJ. Our mission is, providing food, clothing, hygiene products, spiritual guidance/counseling, social service, drug and alcohol referrals, Social Workers, and Street Med RX. to the community members experiencing homelessness, by delivering essential services of support directly to the street-dwelling Homeless community, the LGBTQIA+ community, and at-risk youth living on the street in New Jersey. We bridge the gap between these marginalized communities and the resources they need, meeting them exactly where they are, offering a Hand up, Not just a Handout.</p>
                <a href="https://streetlifeministry.org" target="_blank">https://streetlifeministry.org</a>
                <!--
                <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div> -->
              </div>
            </details>
          </div>

          <!-- Then, TRUE Mentors -->
          <div v-show=false class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">TRUE Mentors</summary>
              <div class="p-4">
                <p class="mb-2">Other Description</p>
                <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div>
              </div>
            </details>
          </div>

          <!-- Then, Community Lifestyle -->
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Community Lifestyle</summary>
              <div class="p-4">
                <p class="mb-2">In the span of 7 remarkable years, Community Lifestyle has blossomed into a beacon of hope and support for the youth and teens in the Hoboken Housing Authority community. Our relentless commitment to fostering an environment of growth, learning, and connectivity has touched over 400 young lives, setting them on a path to a brighter future.<br><br>Join Community Lifestyle in our mission to empower, understand, stabilize, and connect the youth of Hoboken. Every action you take, big or small, contributes to building a brighter future for our community’s young minds.</p>
                <a href="https://www.communitylifestyle.org" target="_blank">https://www.communitylifestyle.org</a>
                <!--
                <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div> -->
              </div>
            </details>
          </div>

          <!-- Party with Purpose-->
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Party with Purpose</summary>
              <div class="p-4">
                <p class="mb-2">Giving back for 20 years and counting! Party With Purpose (PWP) is a 100% volunteer-based, 501(c)(3) not-for-profit organization founded on the concept of producing great events to raise money for children’s charities and bring the community together in the process.<br><br>Founded in Hoboken, N.J. 2002, by Scott Delea, who following the attacks on 9-11 wanted to make a difference in his own local community. PWP has donated over $1 million to local branches of charities such as the Boys and Girls Club, TRUE Mentors, Community Lifestyle, Hoboken Historical Museum children’s programming, H.O.P.E.S., United Children's Music Project (UCMP) and more. Beginning with the first event in December 2002, PWP was born to meet the needs of both local charities as well as a community looking to get involved.</p>
                <a href="https://www.partywithpurpose.org" target="_blank">https://www.partywithpurpose.org</a>

                <!-- <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div>
                -->
              </div>
            </details>
          </div>

          <!-- City Challenge Race Youth Foundation-->
          <div v-show=false class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">City Challenge Race Youth Foundation</summary>
              <div class="p-4">
                <p class="mb-2">Other Description</p>
                <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div>
              </div>
            </details>
          </div>
    
          <!--The Hoboken Family Alliance-->
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">The Hoboken Family Alliance</summary>
              <div class="p-4">
                <p class="mb-2">Our mission is to support families and our community through philanthropy, civic initiatives, special needs programs, parenting events and educational support.<br><br>HFA is a registered 501 3-c non-profit with over 20+ years of legacy impact. HFA has grown into a robust organization that spurs community spirit and change for the better – across civic, social, and educational opportunities for all families while bringing families together in our community.</p>
                <a href="https://hobokenfamily.org" target="_blank">https://hobokenfamily.org</a>

                <!-- <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div>
                -->
              </div>
            </details>
          </div>


          <!--Hoboken Unified Giving-->
          <div v-show=false class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Hoboken Unified Giving</summary>
              <div class="p-4">
                <p class="mb-2">Other Description</p>
                <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div>
              </div>
            </details>
          </div>

          <!--Amazing Truth Society-->
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Amazing Truth Society</summary>
              <div class="p-4">
                <p class="mb-2">Amazing Truth Society's mission is to serve low-income communities throughout Hoboken, N.J.  by providing services including karate training and guitar lessons. We teach students how to develop lifelong self-sufficiency as well as major academic improvement. We are committed to providing families with the highest standard of professional martial arts that revolves around giving both children and adults unique yet traditional set of karate training while including various opportunities for educational improvement. We provide solutions that make a long-lasting difference within a child with the help from Hoboken Library.<br><br>We offer free guitar lessons taught once a month on Wednesdays, Nerf Wars and in house movie nights provided for the kids once a month. Amazing Truth Society has also added an exciting new program to the school called, “Speak Up & Stand Out against Bullies” which is an anti-bullying program that teaches kids to identify predatory children around them – bullies – and try to maneuver themselves around those types of kids. </p>
                <a href="https://amazingtruthsociety.org" target="_blank">https://amazingtruthsociety.org</a>

                <!-- <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div>
                -->
              </div>
            </details>
          </div>

          <!--St. Francis Church-->
          <div v-show=false class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">St. Francis Church</summary>
              <div class="p-4">
                <p class="mb-2">Other Description</p>
                <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div>
              </div>
            </details>
          </div>

          <!--United Synagogue of Hoboken-->
          <div v-show=false class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">United Synagogue of Hoboken</summary>
              <div class="p-4">
                <p class="mb-2">Other Description</p>
                <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div>
              </div>
            </details>
          </div>
        </div>
        <!--Last row for Hoboken-->
        

        <!--Start of Jersey City-->
        <div class="flex justify-center font-bold text-white text-3xl">Jersey City, NJ</div>
        <!--Temple Beth-El Pantry-->
        <div class="flex flex-wrap justify-center justify-evenly">
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
            <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Temple Beth-El Pantry</summary>
              <div class="p-4">
                <p class="mb-2">Temple Beth-El, the Reform Jewish congregation of Jersey City is a warm, welcoming community where folks worship, serve, sing, study, question, aspire, and celebrate.<br><br>Although we collect many donations during our High Holiday Food Drive, our obligations to those in need do not end on Yom Kippur. Who will make sure there is food available for those who need it in November, and December, and beyond? We feed our homeless and hungry neighbors in need every Thursday afternoon 5:30pm - 6pm, all year-long.</p>
                <a href="https://www.betheljc.org/interact/social-action/food-pantry-donation-list" target="_blank">https://www.betheljc.org/interact/social-action/food-pantry-donation-list</a>
                
                <!--
                <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div>
                -->
              </div>
            </details>
          </div>

          <!--Let's Celebrate-->
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Let's Celebrate</summary>
                <div class="p-4">
                  <p class="mb-2">Let's Celebrate operates out of a renovated church in Jersey City (46 Fairview Avenue). They have a free-choice food pantry in the main sanctuary as well as a breakfast / lunch program downstairs.</p>
                  
                  <!-- <p class="mb-2">Lead to Video</p>
                  <div class="aspect-w-16 aspect-h-9">
                    <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                  </div> -->
                </div>
            </details>
          </div>

          <!--Team Wilderness-->
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Team Wilderness</summary>
                <div class="p-4">
                  <p class="mb-2">Team Wilderness grows teamwork, leadership, and character within our urban youth through wilderness excursions. Team Wilderness is a nonprofit organization that uses an experiential educational model to teach urban teenagers teamwork, leadership, and character. The organization helps to promote confidence in youth, consideration for their peers, passion for the world they live in, and resilience both as an individual and a group. Facing physical and social challenges, they learn to adapt and develop real-life problem-solving skills while at the same time overcoming fears and obstacles.<br><br>Team Wilderness is the only wilderness experiential education organization offering programs focusing specifically on Hudson County's most underserved youth. Our programs are affordable for all income levels; since they cater to the youth from the community, they help positively impact the community itself.</p>
                  <a href="https://www.teamwilderness.org" target="_blank">https://www.teamwilderness.org</a>

                  <!-- <p class="mb-2">Lead to Video</p>
                  <div class="aspect-w-16 aspect-h-9">
                    <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                  </div> -->
                </div>
            </details>
          </div>

          <!--Love Thy Neighbor-->
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Love Thy Neighbor</summary>
                <div class="p-4">
                  <p class="mb-2">The congregation of Jerusalem Missionary Baptist Church in Jersey City operates a food & clothing distribution program once a month from their church basement.</p>

                  <!-- <p class="mb-2">Lead to Video</p>
                  <div class="aspect-w-16 aspect-h-9">
                    <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                  </div> -->
                </div>
              </details>
          </div>

          <!--Welcome Home-->
          <div v-show=false class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Welcome Home</summary>
                <div class="p-4">
                  <p class="mb-2">Other Description</p>
                  <p class="mb-2">Lead to Video</p>
                  <div class="aspect-w-16 aspect-h-9">
                    <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                  </div>
                </div>
              </details>
          </div>
        </div>

        <!--Start of West New York-->
        <div class="flex justify-center font-bold text-white text-3xl">West New York, NJ</div>
        <!--Row-->
        <div class="flex flex-wrap justify-center justify-evenly pb-20">
          <!--Dvine Konektion-->
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">Dvine Konektion</summary>
              <div class="p-4">
                <p class="mb-2">Dvine Konektion is a non-profit / 501c3, all-volunteer organization based in West New York, NJ. Founded by Tania Fuentes back in 2008, Dvine Konektion is a non-profit organization that focuses on several key areas: Family, community, youth at risk, hunger relief, domestic violence prevention, and awareness. Every month we serve between 100 to 600 families by supplying bags of love filled with groceries. By providing medical screenings such as eye and cholesterol exams, blood pressure, and glucose testing, we're able to bring change into the community.<br><br>Through our outreach organization and partnerships, we're able to accomplish this. Dvine Konektion services our local cities in Hudson County: West New York, North Bergen, Guttenberg, Weehawken, Jersey City, Kearny, Fort Lee, Union City, Hoboken, and more. Since our inception in 2008, we have helped thousands of families in need.</p>
                <a href="https://www.dvinek.org" target="_blank">https://www.dvinek.org</a>

                <!-- 
                <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div> -->
              </div>
            </details>
          </div>
          <!--The Open Door NJNY-->
          <div class="w-full lg:w-1/2 xl:w-1/3 p-4">
            <details class="bg-white rounded-lg p-6 shadow-md mt-4 mb-4">
              <summary class="bg-white-500 hover:bg-white-700 text-black font-bold py-2 px-4 rounded-full cursor-pointer">The Open Door NJNY</summary>
              <div class="p-4">
                <p class="mb-2">The Open Door NJNY gives immigrant families the support they need to be strong, contributing members of society. We do this by offering weekly English, GED Math, and computer classes as well as community building activities. We currently serve over 600 people.<br><br>Our Founders Luis and Maggie Iza immigrated from Cuba years ago. Their parents, who eagerly learned English and were avid readers, inspired them pursue education, learn English, and integrate into their new communities in the United States. Remembering the challenges they experienced when they first arrived to the United States, the founders started The Open Door to offer new Americans the opportunity to be part of mainstream society.<br><br>Recognizing that the language barrier was a great risk factor for poverty and its effects, the Izas began The Open Door NJNY as an ESL program and later expanded it to offer computer classes and preparation classes for the high school equivalency exam. The Open Door NJNY opened a second location in New York in 2013 and since then we have served over 600 students.</p>
                <a href="https://www.theopendoornjny.org" target="_blank">https://www.theopendoornjny.org</a>

                <!-- <p class="mb-2">Lead to Video</p>
                <div class="aspect-w-16 aspect-h-9">
                  <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                </div> -->
              </div>
            </details>
          </div>
        </div>
    </div>
</div>
  

    <!-- Contest Rules -->
    <div v-show=false class="text-center">
      <div class="inline-block">
        <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer"
          class="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mb-8">
            Contest Rules
        </a>
      </div>
    </div>
  


</template>

<script>
export default {
  name: "ClashOfClubs",
  methods: {}
}
</script>
<template>
    <img :src="icon"/>
</template>

<script>
import { ref } from 'vue';



// Minis
import Mini_Generic from "@/assets/medallions/CQ-Medallion-Mini.png";
import Mini_Beast from "@/assets/medallions/CQ-Medallion-Mini_Beast.png";
import Mini_Blood from "@/assets/medallions/CQ-Medallion-Mini_Blood.png";
import Mini_Body from "@/assets/medallions/CQ-Medallion-Mini_Body.png";
import Mini_Craft from "@/assets/medallions/CQ-Medallion-Mini_Craft.png";
import Mini_Cure from "@/assets/medallions/CQ-Medallion-Mini_Cure.png";
import Mini_Defender from "@/assets/medallions/CQ-Medallion-Mini_Defender.png";
import Mini_Earth from "@/assets/medallions/CQ-Medallion-Mini_Earth.png";
import Mini_Fire from "@/assets/medallions/CQ-Medallion-Mini_Fire.png";
import Mini_Food from "@/assets/medallions/CQ-Medallion-Mini_Food.png";
import Mini_Garment from "@/assets/medallions/CQ-Medallion-Mini_Garment.png";
import Mini_Hygiene from "@/assets/medallions/CQ-Medallion-Mini_Hygiene.png";
import Mini_Life from "@/assets/medallions/CQ-Medallion-Mini_Life.png";
import Mini_Mind from "@/assets/medallions/CQ-Medallion-Mini_Mind.png";
import Mini_Nature from "@/assets/medallions/CQ-Medallion-Mini_Nature.png";
import Mini_Shadow from "@/assets/medallions/CQ-Medallion-Mini_Shadow.png";
import Mini_Soul from "@/assets/medallions/CQ-Medallion-Mini_Soul.png";
import Mini_Speech from "@/assets/medallions/CQ-Medallion-Mini_Speech.png";
import Mini_Storm from "@/assets/medallions/CQ-Medallion-Mini_Storm.png";
import Mini_Time from "@/assets/medallions/CQ-Medallion-Mini_Time.png";
import Mini_Toxin from "@/assets/medallions/CQ-Medallion-Mini_Toxin.png";
import Mini_Treasure from "@/assets/medallions/CQ-Medallion-Mini_Treasure.png";
import Mini_Voyage from "@/assets/medallions/CQ-Medallion-Mini_Voyage.png";
import Mini_War from "@/assets/medallions/CQ-Medallion-Mini_War.png";
import Mini_Water from "@/assets/medallions/CQ-Medallion-Mini_Water.png";
import Mini_Youth from "@/assets/medallions/CQ-Medallion-Mini_Youth.png";



// Mediums
import Medium_Generic from "@/assets/medallions/CQ-Medallion.png";
import Medium_Beast from "@/assets/medallions/CQ-Medallion_Beast.png";
import Medium_Blood from "@/assets/medallions/CQ-Medallion_Blood.png";
import Medium_Body from "@/assets/medallions/CQ-Medallion_Body.png";
import Medium_Craft from "@/assets/medallions/CQ-Medallion_Craft.png";
import Medium_Cure from "@/assets/medallions/CQ-Medallion_Cure.png";
import Medium_Defender from "@/assets/medallions/CQ-Medallion_Defender.png";
import Medium_Earth from "@/assets/medallions/CQ-Medallion_Earth.png";
import Medium_Fire from "@/assets/medallions/CQ-Medallion_Fire.png";
import Medium_Food from "@/assets/medallions/CQ-Medallion_Food.png";
import Medium_Garment from "@/assets/medallions/CQ-Medallion_Garment.png";
import Medium_Hygiene from "@/assets/medallions/CQ-Medallion_Hygiene.png";
import Medium_Life from "@/assets/medallions/CQ-Medallion_Life.png";
import Medium_Mind from "@/assets/medallions/CQ-Medallion_Mind.png";
import Medium_Nature from "@/assets/medallions/CQ-Medallion_Nature.png";
import Medium_Shadow from "@/assets/medallions/CQ-Medallion_Shadow.png";
import Medium_Soul from "@/assets/medallions/CQ-Medallion_Soul.png";
import Medium_Speech from "@/assets/medallions/CQ-Medallion_Speech.png";
import Medium_Storm from "@/assets/medallions/CQ-Medallion_Storm.png";
import Medium_Time from "@/assets/medallions/CQ-Medallion_Time.png";
import Medium_Toxin from "@/assets/medallions/CQ-Medallion_Toxin.png";
import Medium_Treasure from "@/assets/medallions/CQ-Medallion_Treasure.png";
import Medium_Voyage from "@/assets/medallions/CQ-Medallion_Voyage.png";
import Medium_War from "@/assets/medallions/CQ-Medallion_War.png";
import Medium_Water from "@/assets/medallions/CQ-Medallion_Water.png";
import Medium_Youth from "@/assets/medallions/CQ-Medallion_Youth.png";

// Large (TODO)

// Miscs
import Mini_Warning from "@/assets/medallions/CQ-Mini-Problem-Triangle.png";
import Medium_Warning from "@/assets/medallions/CQ-Problem-Triangle.png";
import Mini_Locked from "@/assets/medallions/CQ-Mini-Locked-Quest.png";
import Medium_Locked from "@/assets/medallions/CQ-Locked-Quest.png";

// Stamp Icons
import Stamp_Bag_01 from "@/assets/stamps/stamp_bag_01.png";
import Stamp_Box_01 from "@/assets/stamps/stamp_box_01.png";
import Stamp_Bread_01 from "@/assets/stamps/stamp_bread_01.png";
import Stamp_Brush_01 from "@/assets/stamps/stamp_brush_01.png";
import Stamp_Clothing_01 from "@/assets/stamps/stamp_clothing_01.png";
import Stamp_Envelope_01 from "@/assets/stamps/stamp_envelope_01.png";
import Stamp_Flower_01 from "@/assets/stamps/stamp_flower_01.png";
import Stamp_Gem_01 from "@/assets/stamps/stamp_gem_01.png";
import Stamp_Kid_01 from "@/assets/stamps/stamp_kid_01.png";
import Stamp_Knife_01 from "@/assets/stamps/stamp_knife_01.png";
import Stamp_People_01 from "@/assets/stamps/stamp_people_01.png";
import Stamp_Pencil_01 from "@/assets/stamps/stamp_pencil_01.png";
import Stamp_Run_01 from "@/assets/stamps/stamp_run_01.png";
import Stamp_Salad_01 from "@/assets/stamps/stamp_salad_01.png";
import Stamp_Shelf_01 from "@/assets/stamps/stamp_shelf_01.png";
import Stamp_Shield_01 from "@/assets/stamps/stamp_shield_01.png";
import Stamp_Shovel_01 from "@/assets/stamps/stamp_shovel_01.png";
import Stamp_Speech_01 from "@/assets/stamps/stamp_speech_01.png";
import Stamp_Tree_01 from "@/assets/stamps/stamp_tree_01.png";
import Stamp_Tree_02 from "@/assets/stamps/stamp_tree_02.png";
import Stamp_Truck_01 from "@/assets/stamps/stamp_truck_01.png";
import Stamp_Wrench_01 from "@/assets/stamps/stamp_wrench_01.png";


export default {
  name: "IconPicker",
  props: {
    name: String,
    size: { default: "tiny", type: String }
  },
  setup(props) {
    // console.log("Props.name is " + props.name);

    const icon = ref("");

    switch(props.name) {
        case "generic medallion":
            return props.size === 'tiny' ? { icon: Mini_Generic } 
                 : props.size === 'medium'  ? { icon: Medium_Generic } 
                 : props.size === 'large' ? { icon: Medium_Generic }
                 : { icon: Mini_Generic }

        case "warning triangle":
            return props.size === 'tiny' ? { icon: Mini_Warning } 
                 : props.size === 'medium'  ? { icon: Medium_Warning } 
                 : props.size === 'large' ? { icon: Medium_Warning }
                 : { icon: Mini_Warning }

        case "locked quest":
            return props.size === 'tiny' ? { icon: Mini_Locked } 
                 : props.size === 'medium'  ? { icon: Medium_Locked } 
                 : props.size === 'large' ? { icon: Medium_Locked }
                 : { icon: Mini_Locked }

        // Stamps
        case "stamp_bag_01":
            return props.size === 'tiny' ? { icon: Stamp_Bag_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Bag_01 } 
                 : props.size === 'large' ? { icon: Stamp_Bag_01 }
                 : { icon: Stamp_Bag_01 }
                 
        case "stamp_box_01":
            return props.size === 'tiny' ? { icon: Stamp_Box_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Box_01 } 
                 : props.size === 'large' ? { icon: Stamp_Box_01 }
                 : { icon: Stamp_Box_01 }

        case "stamp_bread_01":
            return props.size === 'tiny' ? { icon: Stamp_Bread_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Bread_01 } 
                 : props.size === 'large' ? { icon: Stamp_Bread_01 }
                 : { icon: Stamp_Bread_01 }

        case "stamp_brush_01":
            return props.size === 'tiny' ? { icon: Stamp_Brush_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Brush_01 } 
                 : props.size === 'large' ? { icon: Stamp_Brush_01 }
                 : { icon: Stamp_Brush_01 }

        case "stamp_clothing_01":
            return props.size === 'tiny' ? { icon: Stamp_Clothing_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Clothing_01 } 
                 : props.size === 'large' ? { icon: Stamp_Clothing_01 }
                 : { icon: Stamp_Clothing_01 }

        case "stamp_envelope_01":
            return props.size === 'tiny' ? { icon: Stamp_Envelope_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Envelope_01 } 
                 : props.size === 'large' ? { icon: Stamp_Envelope_01 }
                 : { icon: Stamp_Envelope_01 }

        case "stamp_flower_01":
            return props.size === 'tiny' ? { icon: Stamp_Flower_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Flower_01 } 
                 : props.size === 'large' ? { icon: Stamp_Flower_01 }
                 : { icon: Stamp_Flower_01 }

        case "stamp_gem_01":
            return props.size === 'tiny' ? { icon: Stamp_Gem_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Gem_01 } 
                 : props.size === 'large' ? { icon: Stamp_Gem_01 }
                 : { icon: Stamp_Gem_01 }
        
        case "stamp_kid_01":
            return props.size === 'tiny' ? { icon: Stamp_Kid_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Kid_01 } 
                 : props.size === 'large' ? { icon: Stamp_Kid_01 }
                 : { icon: Stamp_Kid_01 }

        case "stamp_knife_01":
            return props.size === 'tiny' ? { icon: Stamp_Knife_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Knife_01 } 
                 : props.size === 'large' ? { icon: Stamp_Knife_01 }
                 : { icon: Stamp_Knife_01 }

        case "stamp_pencil_01":
            return props.size === 'tiny' ? { icon: Stamp_Pencil_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Pencil_01 } 
                 : props.size === 'large' ? { icon: Stamp_Pencil_01 }
                 : { icon: Stamp_Pencil_01 }
        
        case "stamp_people_01":
            return props.size === 'tiny' ? { icon: Stamp_People_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_People_01 } 
                 : props.size === 'large' ? { icon: Stamp_People_01 }
                 : { icon: Stamp_People_01 }

        case "stamp_run_01":
            return props.size === 'tiny' ? { icon: Stamp_Run_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Run_01 } 
                 : props.size === 'large' ? { icon: Stamp_Run_01 }
                 : { icon: Stamp_Run_01 }

        case "stamp_salad_01":
            return props.size === 'tiny' ? { icon: Stamp_Salad_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Salad_01 } 
                 : props.size === 'large' ? { icon: Stamp_Salad_01 }
                 : { icon: Stamp_Salad_01 }

        case "stamp_shelf_01":
            return props.size === 'tiny' ? { icon: Stamp_Shelf_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Shelf_01 } 
                 : props.size === 'large' ? { icon: Stamp_Shelf_01 }
                 : { icon: Stamp_Shelf_01 }

        case "stamp_shield_01":
            return props.size === 'tiny' ? { icon: Stamp_Shield_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Shield_01 } 
                 : props.size === 'large' ? { icon: Stamp_Shield_01 }
                 : { icon: Stamp_Shield_01 }

        case "stamp_shovel_01":
            return props.size === 'tiny' ? { icon: Stamp_Shovel_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Shovel_01 } 
                 : props.size === 'large' ? { icon: Stamp_Shovel_01 }
                 : { icon: Stamp_Shovel_01 }

        case "stamp_speech_01":
            return props.size === 'tiny' ? { icon: Stamp_Speech_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Speech_01 } 
                 : props.size === 'large' ? { icon: Stamp_Speech_01 }
                 : { icon: Stamp_Speech_01 }

        case "stamp_tree_01":
            return props.size === 'tiny' ? { icon: Stamp_Tree_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Tree_01 } 
                 : props.size === 'large' ? { icon: Stamp_Tree_01 }
                 : { icon: Stamp_Tree_01 }

        case "stamp_tree_02":
            return props.size === 'tiny' ? { icon: Stamp_Tree_02 } 
                 : props.size === 'medium'  ? { icon: Stamp_Tree_02 } 
                 : props.size === 'large' ? { icon: Stamp_Tree_02 }
                 : { icon: Stamp_Tree_02 }

        case "stamp_truck_01":
            return props.size === 'tiny' ? { icon: Stamp_Truck_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Truck_01 } 
                 : props.size === 'large' ? { icon: Stamp_Truck_01 }
                 : { icon: Stamp_Truck_01 }

        case "stamp_wrench_01":
            return props.size === 'tiny' ? { icon: Stamp_Wrench_01 } 
                 : props.size === 'medium'  ? { icon: Stamp_Wrench_01 } 
                 : props.size === 'large' ? { icon: Stamp_Wrench_01 }
                 : { icon: Stamp_Wrench_01 }

        // Medallions
        case "beast":
            return props.size === 'tiny' ? { icon: Mini_Beast } 
                 : props.size === 'medium'  ? { icon: Medium_Beast } 
                 : props.size === 'large' ? { icon: Medium_Beast }
                 : { icon: Mini_Beast }
        
        case "blood":
            return props.size === 'tiny' ? { icon: Mini_Blood } 
                 : props.size === 'medium'  ? { icon: Medium_Blood } 
                 : props.size === 'large' ? { icon: Medium_Blood }
                 : { icon: Mini_Blood }

        case "body":
            return props.size === 'tiny' ? { icon: Mini_Body } 
                 : props.size === 'medium'  ? { icon: Medium_Body } 
                 : props.size === 'large' ? { icon: Medium_Body }
                 : { icon: Mini_Body }

        case "craft":
            return props.size === 'tiny' ? { icon: Mini_Craft } 
                 : props.size === 'medium'  ? { icon: Medium_Craft } 
                 : props.size === 'large' ? { icon: Medium_Craft }
                 : { icon: Mini_Craft }

        case "cure":
            return props.size === 'tiny' ? { icon: Mini_Cure } 
                 : props.size === 'medium'  ? { icon: Medium_Cure } 
                 : props.size === 'large' ? { icon: Medium_Cure }
                 : { icon: Mini_Cure }

        case "defender":
            return props.size === 'tiny' ? { icon: Mini_Defender } 
                 : props.size === 'medium'  ? { icon: Medium_Defender } 
                 : props.size === 'large' ? { icon: Medium_Defender }
                 : { icon: Mini_Defender }

        case "earth":
            return props.size === 'tiny' ? { icon: Mini_Earth } 
                 : props.size === 'medium'  ? { icon: Medium_Earth } 
                 : props.size === 'large' ? { icon: Medium_Earth }
                 : { icon: Mini_Earth }

        case "fire":
            return props.size === 'tiny' ? { icon: Mini_Fire } 
                 : props.size === 'medium'  ? { icon: Medium_Fire } 
                 : props.size === 'large' ? { icon: Medium_Fire }
                 : { icon: Mini_Fire }

        case "food":
            return props.size === 'tiny' ? { icon: Mini_Food } 
                 : props.size === 'medium'  ? { icon: Medium_Food } 
                 : props.size === 'large' ? { icon: Medium_Food }
                 : { icon: Mini_Food }

        case "garment":
            return props.size === 'tiny' ? { icon: Mini_Garment } 
                 : props.size === 'medium'  ? { icon: Medium_Garment } 
                 : props.size === 'large' ? { icon: Medium_Garment }
                 : { icon: Mini_Garment }

        case "hygiene":
            return props.size === 'tiny' ? { icon: Mini_Hygiene } 
                 : props.size === 'medium'  ? { icon: Medium_Hygiene } 
                 : props.size === 'large' ? { icon: Medium_Hygiene }
                 : { icon: Mini_Hygiene }

        case "life":
            return props.size === 'tiny' ? { icon: Mini_Life } 
                 : props.size === 'medium'  ? { icon: Medium_Life } 
                 : props.size === 'large' ? { icon: Medium_Life }
                 : { icon: Mini_Life }

        case "mind":
            return props.size === 'tiny' ? { icon: Mini_Mind } 
                 : props.size === 'medium'  ? { icon: Medium_Mind } 
                 : props.size === 'large' ? { icon: Medium_Mind }
                 : { icon: Mini_Mind }

        case "nature":
            return props.size === 'tiny' ? { icon: Mini_Nature } 
                 : props.size === 'medium'  ? { icon: Medium_Nature } 
                 : props.size === 'large' ? { icon: Medium_Nature }
                 : { icon: Mini_Nature }
        
        case "shadow":
            return props.size === 'tiny' ? { icon: Mini_Shadow } 
                 : props.size === 'medium'  ? { icon: Medium_Shadow } 
                 : props.size === 'large' ? { icon: Medium_Shadow }
                 : { icon: Mini_Shadow }

        case "soul":
            return props.size === 'tiny' ? { icon: Mini_Soul } 
                 : props.size === 'medium'  ? { icon: Medium_Soul } 
                 : props.size === 'large' ? { icon: Medium_Soul }
                 : { icon: Mini_Soul }

        case "speech":
            return props.size === 'tiny' ? { icon: Mini_Speech } 
                 : props.size === 'medium'  ? { icon: Medium_Speech } 
                 : props.size === 'large' ? { icon: Medium_Speech }
                 : { icon: Mini_Speech }

        case "storm":
            return props.size === 'tiny' ? { icon: Mini_Storm } 
                 : props.size === 'medium'  ? { icon: Medium_Storm } 
                 : props.size === 'large' ? { icon: Medium_Storm }
                 : { icon: Mini_Storm }

        case "time":
            return props.size === 'tiny' ? { icon: Mini_Time } 
                 : props.size === 'medium'  ? { icon: Medium_Time } 
                 : props.size === 'large' ? { icon: Medium_Time }
                 : { icon: Mini_Time }
                 
        case "toxin":
            return props.size === 'tiny' ? { icon: Mini_Toxin } 
                 : props.size === 'medium'  ? { icon: Medium_Toxin } 
                 : props.size === 'large' ? { icon: Medium_Toxin }
                 : { icon: Mini_Toxin }

        case "treasure":
            return props.size === 'tiny' ? { icon: Mini_Treasure } 
                 : props.size === 'medium'  ? { icon: Medium_Treasure } 
                 : props.size === 'large' ? { icon: Medium_Treasure }
                 : { icon: Mini_Treasure }

        case "voyage":
            return props.size === 'tiny' ? { icon: Mini_Voyage } 
                 : props.size === 'medium'  ? { icon: Medium_Voyage } 
                 : props.size === 'large' ? { icon: Medium_Voyage }
                 : { icon: Mini_Voyage }

        case "war":
            return props.size === 'tiny' ? { icon: Mini_War } 
                 : props.size === 'medium'  ? { icon: Medium_War } 
                 : props.size === 'large' ? { icon: Medium_War }
                 : { icon: Mini_War }

        case "water":
            return props.size === 'tiny' ? { icon: Mini_Water } 
                 : props.size === 'medium'  ? { icon: Medium_Water } 
                 : props.size === 'large' ? { icon: Medium_Water }
                 : { icon: Mini_Water }

        case "youth":
            return props.size === 'tiny' ? { icon: Mini_Youth } 
                 : props.size === 'medium'  ? { icon: Medium_Youth } 
                 : props.size === 'large' ? { icon: Medium_Youth }
                 : { icon: Mini_Youth }
        
        default:
            return { icon: Mini_Generic }
    }
  },
  methods: {
    onClick() {
      //console.log("Clicking on Quest ID " + this.quest.id + "...")      
    }
  },
  computed: {
    
  }
};
</script>

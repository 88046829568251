<template>
  <Teleport to="#modalScreen">
    <div
      class="fixed top-0 left-0 w-screen h-screen z-80 flex justify-center items-start md:items-center"
    >
      <!-- Black Background -->
      <div
        name="Blackground"
        class="bg-black opacity-75 w-screen h-screen absolute top-0 left-0"
        @click="() => close()"
      />
      <div
        class="bg-white w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/3 2xl:w-1/2 mx-auto top-20 bottom-5 md:bottom-20 absolute py-5 px-5 rounded-md overflow-auto"
        style="max-height: 90vh;"
      >
        <h1 class="text-2xl font-bold text-center">
          Meal Donation Form
        </h1>
        <p>
          Thank you for expressing interest in donating a feast to our guests!
          This is a very big donation, so we'd like a bit more information from
          you before reserving this meal donation for you.
        </p>
        <div class="questionbox">
          <p class="font-bold text-left">
            Please describe the meal you are preparing in detail (with the
            amounts included) so the Kitchen Manager knows what to expect and we
            can assess if it will be enough food.
          </p>
          <textarea
            v-model="question1"
            class="w-full h-20 rounded-md"
            :class="{
              'border-red-500': errorQuestion1,
              'border-1': errorQuestion1
            }"
            @input="validateQuestion1"
          />
          <div class="self-end">
            <p class="text-xs">{{ question1.length }} / 250 characters</p>
            <p class="text-xs">Required Characters: 30</p>
          </div>
        </div>
        <div class="questionbox t">
          <p class="font-bold text-left pb-0">
            Please confirm that you are bringing enough to feet 100 people. (If
            it's not enough, you can always drop it off, but please don't submit
            this form).
          </p>
          <div class="flex flex-col">
            <div>
              <input
                type="radio"
                id="yes"
                name="question2"
                value="Yes, it's plenty!"
                v-model="question2"
              />
              <label for="yes">Yes, it's plenty!</label>
            </div>
            <div>
              <input
                type="radio"
                id="no"
                name="question2"
                value="No, it's not enough."
                v-model="question2"
              />
              <label for="no">No, it's not enough.</label>
            </div>
          </div>
        </div>
        <div class="questionbox">
          <p class="font-bold text-left">
            Are you bringing cooked food that just needs to be heated up, or are
            you planning on cooking the entire meal from scratch in our kitchen?
          </p>
          <div class="flex flex-col">
            <div>
              <input
                type="radio"
                id="cooked"
                name="question3"
                value="We just need to heat the food up."
                v-model="question3"
              />
              <label for="cooked">
                We just need to heat the food up.
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="scratch"
                name="question3"
                value="We want to cook in the kitchen!"
                v-model="question3"
              />
              <label for="scratch">
                We want to cook in the kitchen!
              </label>
            </div>
          </div>
        </div>
        <div class="questionbox">
          <p class="font-bold text-left">
            Our kitchen has limited space. Please limit yourself of kitchen
            helpers to a maximum of two (not including yourself!) and write
            their names in the box below.
          </p>
          <textarea
            v-model="question4"
            class="w-full h-20 rounded-md"
            :class="{
              'border-red-500': errorQuestion4,
              'border-1': errorQuestion4
            }"
            @input="validateQuestion4"
          />
          <div class="self-end">
            <p class="text-xs">{{ question4.length }} / 250 characters</p>
            <p class="text-xs">Required Characters: 10</p>
          </div>
        </div>
        <div class="questionbox">
          <p class="font-bold text-left">
            If this is the first time you're making a big donation like this, or
            if it's been a while, call the Shelter Manager Rev. Anthony Forbes
            at (201) 620-4792 immediately after submitting this form to go over
            more details.
          </p>
          <div class="flex flex-col">
            <div>
              <input
                type="radio"
                id="yes"
                name="question5"
                value="Yes, I'll call Rev right away."
                v-model="question5"
              />
              <label for="yes">Yes, I'll call Rev right away.</label>
            </div>
          </div>
        </div>
        <button
          v-if="ableToSubmit"
          @click="handleSubmitFeast"
          class="bg-green-500 hover:bg-green-400 cursor-pointer text-white font-bold py-4 px-4 rounded border-2 border-green-100 shadow-xl w-full"
        >
          Submit this survey
        </button>
        <button
          v-else
          class="bg-red-400 cursor-not-allowed text-white font-bold py-4 px-4 rounded border-2 border-red-100 shadow-xl w-full"
        >
          Make changes to the survey before submitting
        </button>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { Teleport } from "vue";
import { validate } from "graphql";
export default {
  name: "DonateFeastModal",
  data() {
    return {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
      errorQuestion1: false,
      errorQuestion4: false
    };
  },
  props: ["close", "signUpToVolunteer", "chariteerId"],
  methods: {
    validateQuestion1() {
      if (this.question1.length < 30 || this.question1.length > 250) {
        return (this.errorQuestion1 = true);
      }
      return (this.errorQuestion1 = false);
    },
    validateQuestion4() {
      if (this.question4.length < 10 || this.question4.length > 250) {
        return (this.errorQuestion4 = true);
      }
      return (this.errorQuestion4 = false);
    },
    formatDate(date) {
      const padZero = num => num.toString().padStart(2, "0");

      let hours = date.getHours();
      const minutes = padZero(date.getMinutes());
      const seconds = padZero(date.getSeconds());
      const ampm = hours >= 12 ? "PM" : "AM";

      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      const month = padZero(date.getMonth() + 1); // Months are 0-based
      const day = padZero(date.getDate());
      const year = date.getFullYear();

      return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
    },
    handleSubmitFeast() {
      const formJson = {
        surveyId: 0,
        submitterId: this.chariteerId,
        submissionTimestamp: this.formatDate(new Date()),
        answers: [
          { questionID: 0, questionType: "paragraph", answer: this.question1 },
          { questionID: 1, questionType: "radio", answer: this.question2 },
          { questionID: 2, questionType: "radio", answer: this.question3 },
          { questionID: 3, questionType: "paragraph", answer: this.question4 },
          { questionID: 4, questionType: "radio", answer: this.question5 }
        ]
      };
      return this.signUpToVolunteer(formJson);
    }
  },
  computed: {
    ableToSubmit() {
      return (
        !this.errorQuestion1 &&
        !this.errorQuestion4 &&
        this.question3 &&
        this.question2 == "Yes, it's plenty!" &&
        this.question5 == "Yes, I'll call Rev right away."
      );
    }
  }
};
</script>

<style scoped>
.are-you-sure-you-want-to-volunteer-modal {
  /* display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
  display: flex;
}

.questionbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

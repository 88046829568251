<template>
  <!-- Styles here apply to the entire "koji" -->
  <div class="xl:w-1/3 xl:mx-auto pt-5 pb-20 mx-20">
    <!-- Main Logo / Hero Image -->
    <!-- Sponsor? -->
    <div class="pb-5">
      <img class="w-full" alt="The Charity Quest logo." src="../assets/CQ-Logo.png" />
    </div>

    <!-- Most Important Links (iOS, Google Play, GiveSendGo) -->
    <div class="flex flex-row justify-evenly pb-10">
      <button class="bg-cq-orange border-none cursor-pointer" @click="(e) => { handleAppStoreClick(e); }">
        <img class="w-full" alt="Button for the App Store." src="../assets/koji/Kojicon_AppStore.png" />
        <p class="font-bold text-white text-lg m-0">App Store</p>
      </button>
      <button class="bg-cq-orange border-none cursor-pointer" @click="(e) => { handleGooglePlayClick(e); }">
        <img class="w-full" alt="Button for the App Store." src="../assets/koji/Kojicon_GooglePlay.png" />
        <p class="font-bold text-white text-lg m-0">Google Play</p>
      </button>
      <button class="bg-cq-orange border-none cursor-pointer" @click="(e) => { handleCrowdfundClick(e); }">
        <img class="w-full" alt="Button for the App Store." src="../assets/koji/Kojicon_Crowdfund.png" />
        <p class="font-bold text-white text-lg m-0">Tip Our Team</p>
      </button>
    </div>

    <!-- Center column of important links -->
    <div class="text-center text-white leading-tight">
      <h2>Charity Quest is on a mission to make it easy to volunteer near you.</h2>
      <p>Download the app or check out our website to browse real volunteer opportunities posted by local charities, ministries, and houses of worship in your area.</p>
      <p>Because our volunteer management platform is offered 100% for free to these charities, we rely on your support to keep the dream alive. Consider leaving a one-time tip using the link above, subscribing using the link below, or inviting us to visit your organization via the links provided.</p>
      <br>
    </div>

    <!-- Center column of important links -->
    <div class="flex flex-col gap-y-4 pb-10">

      <!-- The most recent newsletter -->
      <button class="bg-white hover:bg-gray-100 cursor-pointer text-gray-600 text-lg font-bold py-2 px-4 rounded border-2 border-white border-solid inline-flex items-center shadow-md" @click="(e) => { handleLatestNewsletterClick(e); }">
        <img class="w-10 h-10 mx-2" alt="An envelope." src="../assets/stamps/stamp_envelope_01.png" />
        <span>Read our most recent newsletter</span>
      </button>
      <!-- Do newsletters even make sense anymore? NJ, NY? Staten Island? This might be a mess lol... -->
      
      <!-- Subscription Link -->
      <button class="bg-white hover:bg-gray-100 cursor-pointer text-gray-600 text-lg font-bold py-2 px-4 rounded border-2 border-white border-solid inline-flex items-center shadow-md" @click="(e) => { handleSubscribeClick(e); }">
        <img class="w-10 h-10 mx-2" alt="A bag." src="../assets/stamps/stamp_bag_01.png" />
        <span>Subscribe to get perks & support the project</span>
      </button>

      <!-- Booking a seminar with Frank -->
      <button class="bg-white hover:bg-gray-100 cursor-pointer text-gray-600 text-lg font-bold py-2 px-4 rounded border-2 border-white border-solid inline-flex items-center shadow-md" @click="(e) => { handleBookConsultationClick(e); }">
        <img class="w-10 h-10 mx-2" alt="A speech bubble." src="../assets/stamps/stamp_speech_01.png" />
        <span>Discuss hosting a seminar at your office or school</span>
      </button>
      
      <!-- "Time Tracking and Community Reinvestment Act compliance" -->
      <button class="bg-white hover:bg-gray-100 cursor-pointer text-gray-600 text-lg font-bold py-2 px-4 rounded border-2 border-white border-solid inline-flex items-center shadow-md" @click="(e) => { handleBookConsultationClick(e); }">
        <img class="w-10 h-10 mx-2" alt="A speech bubble." src="../assets/stamps/stamp_speech_01.png" />
        <span>Discuss time tracking solutions & CRA compliance </span>
      </button>

      <!-- Start a Guild -->
      <button class="bg-white hover:bg-gray-100 cursor-pointer text-gray-600 text-lg font-bold py-2 px-4 rounded border-2 border-white border-solid inline-flex items-center shadow-md" @click="(e) => { handleCreateGuildClick(e); }">
        <img class="w-10 h-10 mx-2" alt="A group of people." src="../assets/stamps/stamp_people_01.png" />
        <span>Create your own Guild (Volunteer Group)</span>
      </button>

      <!-- current contest or most recent contest -->
      
      <!-- The Website -->
      <button class="bg-white hover:bg-gray-100 cursor-pointer text-gray-600 text-lg font-bold py-2 px-4 rounded border-2 border-white border-solid inline-flex items-center shadow-md" @click="(e) => { handleWebsiteClick(e); }">
        <img class="w-10 h-10 mx-2" alt="A little pencil, meant for editing the Quest." src="../assets/stamps/stamp_wrench_01.png" />
        <span>Access the Charity Quest website</span>
      </button>

      <!-- SHOW my cool GTA portrait here. And say "Do you want your own volunteer portrait?" It's a link to Subscribing -->
      
    </div>

    <!-- TO DO: Social media row goes here -->
    <div class="flex flex-col lg:flex-row justify-evenly ">
      <button
        type="button"
        @click="(e) => { handleFacebookClick(e); }"
        data-te-ripple-init
        data-te-ripple-color="light"
        class="mb-2 flex rounded px-6 py-2.5 text-xs font-bold uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-md focus:shadow-md focus:outline-none focus:ring-0 active:shadow-md border-none cursor-pointer"
        style="background-color: #1877f2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2 h-3.5 w-3.5"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
        </svg>
        Facebook
      </button>
      
      <button
        type="button"
        @click="(e) => { handleTwitterClick(e); }"
        data-te-ripple-init
        data-te-ripple-color="light"
        class="mb-2 flex rounded px-6 py-2.5 text-xs font-bold uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-md focus:shadow-md focus:outline-none focus:ring-0 active:shadow-md border-none cursor-pointer"
        style="background-color: #1da1f2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2 h-3.5 w-3.5"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
        </svg>
        Twitter
      </button>

      <button
        type="button"
        @click="(e) => { handleInstagramClick(e); }"
        data-te-ripple-init
        data-te-ripple-color="light"
        class="mb-2 flex rounded px-6 py-2.5 text-xs font-bold uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-md focus:shadow-md focus:outline-none focus:ring-0 active:shadow-md border-none cursor-pointer"
        style="background-color: #c13584">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2 h-3.5 w-3.5"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
        </svg>
        Instagram
      </button>


    </div>

    <!--
      TO DO: Remember to change the size of your screen (or use Developer resolution tools)
      to make sure this behaves like Koji does on mobile phones. After all, most people will
      be coming to this from Instagram on their phones!
    -->
    
  </div>
</template>

<script>
export default {
  name: "KojiClone",
  methods: {
    handleAppStoreClick ( e )
    {
      console.log("Going to App Store...");
      window.open("https://apps.apple.com/us/app/charity-quest/id1486635465", '_blank', 'noreferrer');
    },
    handleGooglePlayClick ( e )
    {
      console.log("Going to Google Play...");
      window.open("https://play.google.com/store/apps/details?id=com.GameRevenant.CharityQuest", '_blank', 'noreferrer');
    },
    handleCrowdfundClick ( e )
    {
      console.log("Going to Square...");
      window.open("https://square.link/u/L3oz4KJx", '_blank', 'noreferrer');
    },
    handleSubscribeClick ( e )
    {
      console.log("Going to the Subscribe Page...");
      window.open("https://charityquest.io/subscribe", '_blank', 'noreferrer');
    },
    handleLatestNewsletterClick ( e )
    {
      console.log("Reading most recent newsletter...");
      window.open("https://preview.mailerlite.com/t4b7j3v2e2", '_blank', 'noreferrer');
    },
    handleBookConsultationClick ( e )
    {
      console.log("Booking a consultation...");
      window.open("https://calendly.com/charity-quest", '_blank', 'noreferrer');
    },
    handleCreateGuildClick ( e )
    {
      console.log("Creating a Guild...");
      window.open("https://docs.google.com/forms/d/e/1FAIpQLSeEsMKbzvbYF8NompK5qjSA6fkcnJpXe0e7H3cHXPXDzOeIXw/viewform?usp=sf_link", '_blank', 'noreferrer');
    },
    handleWebsiteClick ( e )
    {
      console.log("Going to the website...");
      window.open("https://charityquest.io/", '_blank', 'noreferrer');
    },
    handleFacebookClick ( e )
    {
      console.log("Going to our Facebook page...");
      window.open("https://www.facebook.com/charityquestapp", '_blank', 'noreferrer');
    },
    handleTwitterClick ( e )
    {
      console.log("Going to our Twitter profile...");
      window.open("https://twitter.com/CharityQuestApp", '_blank', 'noreferrer');
    },
    handleInstagramClick ( e )
    {
      console.log("Going to our Instagram handle...");
      window.open("https://www.instagram.com/charityquestapp", '_blank', 'noreferrer');
    }
  }
}
</script>